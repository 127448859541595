import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatterDateCc",
})
export class FormatterDateCcPipe implements PipeTransform {
  transform(valor: string): string {
    if (!valor) return "";
    const data = new Date(valor);
    const mes = (data.getMonth() + 1).toString().padStart(2, "0");
    const ano = data.getFullYear().toString().substr(-2);
    return `${mes}/${ano}`;
  }
}
