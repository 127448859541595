import {NavController} from "@ionic/angular";
import {Component, ViewChild, OnInit} from "@angular/core";
import {Platform} from "@ionic/angular";
import {StorageService} from "./services/storage.service";
import {LocationService} from "./services/location.service";
import {CONSTANTS} from "./constants";
import {StoreController} from "../controllers/store.controller";

// import OneSignal from "onesignal-cordova-plugin";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent {
    user: any;
    loginHash: any;
    listItem = [];
    loading: boolean;
    public arrayHome: any;
    public address: any;
    public bodyHome: any;
    visitante: any;
    bodyHomeStorage: Promise<any>;
    listItemLogin = [];

    constructor(private nav: NavController,
                private platform: Platform,
                private storage: StorageService,
                private locationService: LocationService,
                private storeController: StoreController,
    ) {
        if (localStorage.getItem("USER")) {
            this.user = JSON.parse(localStorage.getItem("USER")!);
            this.loginHash = this.user.login_hash;
        } else {
            if (this.platform.is('android') || this.platform.is('ios')) {
                this.nav.navigateRoot('login');
            } else {
                this.getVisitorHome();

                // this.nav.navigateRoot('home');
            }
        }

        window.addEventListener("storage", (event) => {
            if (event.key == "USER") {
                const newTokenValue = event.newValue;
                if (newTokenValue === null) {
                    alert("Faça login novamente");
                    nav.navigateBack("/login");
                    localStorage.clear();
                }
            }
        });

        this.listItem = [
            {
                nomeCampo: "Editar dados",
                link: `/user-edit/${encodeURIComponent(this.loginHash)}`,
            },
            {nomeCampo: "Endereços", link: "/select-adress"},
            {nomeCampo: "Meus cupons", link: "/tabs/cupom"},
            {nomeCampo: "Meus pedidos", link: "/tabs/tab3"},
            {nomeCampo: "Meus preferidos", link: "/tabs/favorites"},
            {nomeCampo: "Fale conosco", link: "/contact"},
            {nomeCampo: "Termos de uso", link: "tabs/privacy-terms/terms"},
            {nomeCampo: "Privacidade", link: "tabs/privacy-terms/privacy"},
            {nomeCampo: "Ajuda", link: "/tabs/help"},
            {nomeCampo: "Convidar amigos", link: "#"},
            {nomeCampo: "Seja lojista", link: "#"},
            {
                nomeCampo: "Sair",
                link: "/login",
                logout() {
                    localStorage.clear();
                },
            }
        ];
    this.listItemLogin = [
      { nomeCampo: "Login", link: "/login" },
      { nomeCampo: "Criar uma conta", link: "" },
      ];
    }

    private getDataAtual() {
        const date = new Date();
        return date.getFullYear() + "-" + date.getMonth() + "-" + date.getDate();
    }

    private getDataAtualCache() {
        return this.storage.getSync("dataCache", false);
    }


    getVisitorHome() {
        console.log('get visitor home');
        this.address = {
            codCidade: "9",
            latitude: "-20.8190114",
            longitude: "-49.3849979",
        };

        this.bodyHome = {
            cep: "",
            codCidade: "9",
            latitude: "-20.8190114",
            longitude: "-49.3849979",
            bairro: "",
        };

        this.storage.get("bodyHome").then((bodyHomeStorage: any) => {
            if (
                bodyHomeStorage == null ||
                this.getDataAtualCache() !== this.getDataAtual()
            ) {
                // console.log("Bodyhome null:: ", this.bodyHome);
                this.locationService
                    .getHome(this.bodyHome, "")
                    .then((bodyHomeService: any) => {
                        // console.log('%c Get Home Address:: ', 'color: white');
                        // console.log(this.bodyHome);
                        // console.log(bodyHomeService);
                        // SETA A HOME NO STORAGE
                        this.storage.setSync("bodyHome", JSON.stringify(bodyHomeService));
                        this.storage.setSync("dataCache", this.getDataAtual());
                        this.nav.navigateRoot('home');
                    });
                return;
            }
            // console.log("Bodyhome not null:: ", this.bodyHome);

            this.nav.navigateRoot('home')
        });
    }

}


// initOneSignal() {
//   OneSignal.setAppId('2b58f31d-905e-4ba3-8ef9-002b4306c41d');
//   // console.log('Init one signal');

//   // OneSignal.setLogLevel(6, 0);

//   OneSignal.setNotificationWillShowInForegroundHandler((data: any) => {

//   });

// OneSignal.setNotificationOpenedHandler(function (jsonData) {
//   // console.log('Click notificacao');
//   // console.log('notificationOpenedCallback: ' + jsonData);

//   localStorage.setItem('notification_click', 'true');
// });

// OneSignal.addSubscriptionObserver((state) => {
//   // console.log('sub state: ', state);
//   if (state?.to?.userId) {
//     // console.log('State id: ', state.to.userId);
//     localStorage.setItem('onesignalId', state.to.userId);
//   } else {
//     localStorage.setItem('onesignalId', '');
//   }
// });

// iOS - Prompts the user for notification permissions.
// eslint-disable-next-line max-len
//    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
// OneSignal.promptForPushNotificationsWithUserResponse(function (accepted) {
//   console.log('User accepted notifications: ' + accepted);
// });
