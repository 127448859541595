import { Injectable } from "@angular/core";
import { AlertController, ToastController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  private toastTypes = {
    error: "danger",
    success: "success",
    warning: "warning",
  };
  constructor(
    private alertCtrl: AlertController,
    private toastCtrl: ToastController
  ) {}

  async genericAlert(
    header: string,
    subHeader: string,
    message: string,
    action: () => void
  ) {
    const alert = await this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: [
        {
          text: "Cancelar",
          handler: () => {
            alert.dismiss();
          },
        },
        {
          text: "Sim",
          handler: () => {
            action();
          },
        },
      ],
    });

    await alert.present();
  }

  async genericToast(
    header: string,
    msg: string,
    type: string,
    position: "top" | "bottom" | "middle" = "top",
    duration: number = 3000
  ) {
    const toast = await this.toastCtrl.create({
      header: header,
      message: msg,
      duration: duration,
      position: position,
      color: this.toastTypes[type],
    });

    await toast.present();
  }
}
