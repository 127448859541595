import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CONSTANTS} from '../constants';

@Injectable({
    providedIn: 'root'
})
export class SearchAddressService {
    private headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');

    constructor(
        private http: HttpClient
    ) {
    }

    searchAddress(searchText:any, uuid:any): Promise<any> {
        const query = new URLSearchParams();
        query.append('searchText', searchText);
        query.append('uuid', uuid);
        const options = {
            headers: this.headers
        };
        const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.SEARCH_ADDRESS}?${query}`;
        return new Promise<any>((resolve, reject) => {
            this.http.get(url, options).subscribe((response) => {
                return resolve(response);
            }, (error) => {
                return reject(error);
            });
        });
    }


    getCountry() {
        const options = {
            headers: this.headers
        };
        const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.GET_COUNTRY}`;
        return new Promise<any>((resolve, reject) => {
            this.http.get(url, options).subscribe((response) => {
                return resolve(response);
            }, (error) => {
                return reject(error);
            });
        });
    }

    hasNumber(address:any) {
        if (address.address_components) {
            return (address.address_components.filter((e:any) => (e.types.find((i:any) => i === 'street_number'))).length > 0);
        }

        return false;
    }

    getEndereco(address:any) {
        let endereco = '';
        let enderecos = [];

        if(address) {
            enderecos = address.filter((e:any) => (e.types.find((i:any) => i === 'route')));
        }

        if (enderecos.length > 0) {
            if (enderecos[0].long_name.toLowerCase() !== 'unnamed road') {
                endereco = enderecos[0].long_name;
            }
        }

        return endereco;
    }

    getNumero(address:any) {
        let numero = '';
        let numeros = [];

        if(address) {
            numeros = address.filter((e:any) => (e.types.find((i:any) => i === 'street_number')));
        }
        if (numeros.length > 0) {
            numero = numeros[0].long_name;
        }
        return numero;
    }

    getBairro(address:any) {
        let bairro = '';
        let bairros = [];

        if(address) {
            bairros = address.filter((e:any) => (e.types.find((i:any) => (i === 'sublocality_level_1') || (i === 'sublocality'))));
        }

        if (bairros.length > 0) {
            bairro = bairros[0].long_name;
        }

        return bairro;
    }

    getCidade(address:any) {
        let cidade = '';
        let cidades = [];

        if(address) {
            cidades = address.filter((e:any) => (e.types.find((i:any) => (i === 'administrative_area_level_2') || (i === 'locality'))));
        }

        if (cidades.length > 0) {
            cidade = cidades[0].long_name;
        }

        return cidade;
    }

    getUf(address:any) {
        let uf = '';
        let ufs = [];

        if(address) {
            ufs = address.filter((e:any) => (e.types.find((i:any) => (i === 'administrative_area_level_1'))));
        }

        if (ufs.length > 0) {
            uf = ufs[0].short_name;
        }

        return uf;
    }

    getPais(address:any) {
        let pais = '';
        let paises = [];

        if(address) {
            paises = address.filter((e:any) => (e.types.find((i:any) => (i === 'country'))));
        }

        if (paises.length > 0) {
            pais = paises[0].long_name;
        }

        return pais;
    }

    getCEP(address:any) {
        let cep = '';
        let ceps = [];

        if(address) {
            ceps = address.filter((e:any) => (e.types.find((i:any) => (i === 'postal_code'))));
        }

        if (ceps.length > 0) {
            cep = ceps[0].long_name;
        }

        return cep;
    }

    public getDistanceInMeters(latLngOrigin:any, latLngDestination:any) {
        const R = 6371;
        const dLat = this.deg2rad(latLngDestination.lat() - latLngOrigin.lat());
        const dLon = this.deg2rad(latLngDestination.lng() - latLngOrigin.lng());
        const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(latLngOrigin.lat())) * Math.cos(this.deg2rad(latLngDestination.lat())) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const distance = (R * (2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)))) * 1000;
        return parseFloat(distance.toFixed(0));
    }

    public deg2rad(deg:any) {
        return deg * (Math.PI / 180);
    }
}
