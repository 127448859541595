import {Injectable} from '@angular/core';
import { CONSTANTS } from 'src/app/constants';
import { EncryptService } from 'src/app/services/encrypt.service';
import { HomeService } from 'src/app/services/home.service';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
    providedIn: 'root'
})

export class StoreController {
    constructor(private storage: StorageService, private encrypt: EncryptService) {}

    public retiraAcentos(str:any) {

        let com_acento = "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ ";
        let sem_acento = "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr-";
        let novastr="";
        for(let i=0; i<str.length; i++) {
            let troca=false;
            for (let a=0; a<com_acento.length; a++) {
                if (str.substr(i,1)==com_acento.substr(a,1)) {
                    novastr+=sem_acento.substr(a,1);
                    troca=true;
                    break;
                }
            }
            if (troca==false) {
                novastr+=str.substr(i,1);
            }
        }
        return novastr.toLowerCase();
    }

    public setLoja(lojas: any[]){
        let newLojas:any[] = [];
        lojas.map((loja: any) => {
            let position = this.setObjectLoja(loja);
            let url      = 'tabs/restaurant/'+this.retiraAcentos(loja.nome);
            newLojas.push(position);
            this.storage.setSync(url, JSON.stringify(position));
        });
        return newLojas;
    }

    public setLojasAbertas(lojasArray:any[], homeService: HomeService, lojasIds: any[]){
        homeService.updateStoreHour(lojasIds).then((lojas:any) => {
            lojas.map((loja: any, index: any) => {
                lojasArray[index].aberto         = loja.aberto;
                lojasArray[index].tempoEntrega   = loja.tempoEntrega;
                lojasArray[index].recebimentoApp = loja.recebimentoApp;
                lojasArray[index].taxaGratis     = loja.taxaGratis;
                lojasArray[index].updatePedido   = loja.updatePedido;
                lojasArray[index].socketNode     = loja.socketNode;
            })
        });

        return lojasArray;
    }

  async setLojasAbertasPag(lojasArray:any[], homeService: HomeService, lojasIds: any[]){
    return await homeService.updateStoreHour(lojasIds);
  }

    public setObjectLoja(loja:any = []){
      // console.log(loja);
        return {
            "perfil":  (!loja.perfil ? CONSTANTS.CONFIG.IMG_PLACEHOLDER : CONSTANTS.CONFIG.IMG_URL + loja.perfil),
            "nome": loja.nome,
            "aberto": loja.aberto == '1' ? 'aberto' : 'fechado',
            "codLoja": loja.codLoja,
            "url": 'tabs/restaurant/'+this.retiraAcentos(loja.nome)+'/'+ this.encrypt.encrypt(loja.codLoja),
            "categoria": loja.categoria,
            "tempoEntrega": loja.tempoEntrega,
            "taxaEntrega": loja.taxaEntrega,
            "distancia": loja.distancia,
            "notaLoja": parseFloat(loja.notaLoja).toFixed(2),
            "delivery": loja.delivery,
            "formaspagamento": loja.formaspagamento,
            "horarios": loja.horarios
        };
    }
}
