import { StorageService } from "src/app/services/storage.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "../constants";
import { rejects } from "assert";
import { url } from "inspector";

@Injectable({
  providedIn: "root",
})
export class HomeService {
  private headers = new HttpHeaders().set(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  constructor(private http: HttpClient, private storage: StorageService) {}

  getHomeContent(
    cep: any,
    lat: any,
    long: any,
    hash: any,
    bairro: any,
    v4: any = 1
  ): Promise<any> {
    // console.log('getHome');
    return new Promise((resolve, reject) => {
      // this.platform.ready().then(() => {
      const url = `${
        CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.HOME
      }?cep=${cep}&bairro=${bairro}&login_hash=${hash}&v4=${v4}&latitude=${lat}&longitude=${long}`;
      this.http.get(url).subscribe(
        (resp) => {
          return resolve(resp);
        },
        (error) => {
          return reject(error);
        }
      );
      // });
    });
  }

  getStore(codLoja: any, type: any): Promise<any> {
    console.log("getStore");
    return new Promise((resolve, reject) => {
      // this.platform.ready().then(() => {
      this.storage
        .get("userPosition")
        .then((pos) => {
          let lat;
          let lng;
          if (pos) {
            lat = pos.lat;
            lng = pos.lng;
          } else {
            lat = 0;
            lng = 0;
          }
          const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.LOJAS}?codLoja=${codLoja}&lat=${lat}&long=${lng}&tipo=${type}`;
          console.log("getStore", url);
          this.http.get(url).subscribe(
            (resp) => {
              return resolve(resp);
            },
            (error) => {
              return reject(error);
            }
          );
        })
        .catch((error) => {
          return reject(error);
        });
      // });
    });
  }

  updateStoreHour(arrayStore: any[]): Promise<any> {
    console.log("updateStoreHour");
    // console.log(arrayStore);
    return new Promise((resolve, reject) => {
      if (arrayStore.length > 0) {
        const url = CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.STOREHOUR;
        const data = {
          arrLojas: arrayStore,
        };
        this.http.post(url, data, { headers: this.headers }).subscribe(
          (resp) => {
            return resolve(resp);
          },
          (error) => {
            return reject(error);
          }
        );
      } else {
        return reject();
      }
    });
  }

  getLogo(urlA: string) {
    return new Promise((resolve, reject) => {
      const url =
        CONSTANTS.CONFIG.IMG_URL + CONSTANTS.CONFIG.AFILIADO_URL + urlA;
      console.log(url);
      
      this.http.get(url).subscribe(
        (resp) => {
          resolve(resp);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
