import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  private key = '3ncRypTk3y';

  constructor() {
  }

  public encrypt(val: any): string {
    const encrypted = CryptoJS.AES.encrypt(val, this.key).toString();
    const toB64 = CryptoJS.enc.Base64.parse(encrypted);

    return toB64.toString(CryptoJS.enc.Hex);
    // return encodeURIComponent(CryptoJS.AES.encrypt(val, this.key.trim()).toString());
  }

  public decrypt(val: string): string {
    const decrypt = CryptoJS.enc.Hex.parse(val);
    const bytes = decrypt.toString(CryptoJS.enc.Base64);
    const fullDecrypt = CryptoJS.AES.decrypt(bytes, this.key);

    return fullDecrypt.toString(CryptoJS.enc.Utf8);

    // return decodeURIComponent(CryptoJS.AES.decrypt(val, this.key.trim()).toString(CryptoJS.enc.Utf8));
  }

  public md5(value: string): string {
    return CryptoJS.MD5(value).toString();
  }
}
