import { CreditCardComponent } from "./../../components/credit-card/credit-card.component";
import { StorageService } from "./../../services/storage.service";
import { CardService } from "./../../services/card.service";
import { AlertService } from "./../../services/alert.service";
import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { IonInput, ModalController } from "@ionic/angular";
import * as creditCardType from "credit-card-type";
import { SimpleMaskModule } from "ngx-ion-simple-mask";
import { CONSTANTS } from "src/app/constants";
import * as moment from "moment";

@Component({
  selector: "app-cartao-credito",
  templateUrl: "./credit-card.page.html",
  styleUrls: ["./credit-card.page.scss"],
})
export class CreditCardPage implements OnInit {
  @ViewChild("cardNumberI", { static: true })
  cardNumberI!: IonInput;
  @ViewChild("nomeI", { static: true }) nomeI!: IonInput;
  @ViewChild("cvcI", { static: true }) cvcI!: IonInput;
  @ViewChild("dateI", { static: true }) dateI!: IonInput;
  cardImage: string = "";
  @Input() closeModal: () => void;

  imgUrl: string =
    "https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/";
  cardNumber: any = "";
  date: any = "";
  nome: any = "";
  cvc: any = "";
  cpf: any;
  cardFlagUrl = "";
  login_hash: "";
  disabled: boolean = false;

  cardInfo: any = creditCardType(this.cardNumber);
  cardBrand = this.cardInfo[0].type;
  cardBrandName: string = "";
  digitShortCode: boolean = false;

  cartao: any = {
    holder: "",
    number: "",
    expiration: "",
    token: " ",
    brand: this.cardBrand,
    cvv: "",
    cpf: "",
    shortCode: "",
  };

  atualizaValorCC() {
    this.cardNumber = this.cardNumberI.value || "0000000000000000";
    this.cardInfo = creditCardType(this.cardNumber) || "";
    this.cardBrandName = this.cardInfo[0].niceType || "";

    this.cardBrand = this.cardInfo[0].type || "";
    console.log(this.cardBrand);
    this.cardFlagUrl = this.imgUrl + this.cardBrand + ".png" || "";
  }
  atualizaValorN() {
    this.nome = this.nomeI.value || "Nome Sobrenome";
  }
  atualizaValorD() {
    console.log(this.dateI.value);

    this.date = this.dateI.value || "02/24";
    this.date = moment(this.dateI.value).format("YYYY-MM");
  }
  atualizaValorC() {
    this.cvc = this.cvcI.value || "000";
  }
  apenasNumeros(event: any) {
    const keyCode = event.keyCode;
    const digitosPermitidos = /^[0-9]*$/;

    if (
      !digitosPermitidos.test(String.fromCharCode(keyCode)) &&
      keyCode !== 8
    ) {
      event.preventDefault();
    }
  }

  montaObj(card) {
    console.log(card);

    this.cartao = {
      holder: card.holder,
      number: card.number,
      expiration: this.date,
      token: "",
      brand: this.cardBrand,
      cvv: card.cvv,
      cpf: card.cpf,
      shortCode: card.shortcode,
    };
  }

  async openConfirm() {
    const modal = await this.modalCtrl.create({
      component: CreditCardComponent,
      cssClass: "modalConfirm",
      componentProps: {
        adicionar: () => {
          this.saveCardInternal(this.cartao);
        },
        cancelar: () => {
          modal.dismiss();
        },
        cartao: this.cartao,
      },
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();
  }

  constructor(
    private toastr: AlertService,
    private cardService: CardService,
    private storage: StorageService,
    private modalCtrl: ModalController
  ) {
    this.cardNumber = "0000000000000000";
    this.date = "02/24";
    this.nome = "Seu nome vai aqui";
    this.cvc = "000";
  }

  saveCard(card) {
    this.disabled = true;

    this.montaObj(card);

    console.log(card);

    if (!this.isNameValid(this.cartao.holder)) {
      this.toastr.genericToast(
        "Atenção",
        "Nome do titular inválido.",
        "error",
        "bottom"
      );
      this.disabled = false;
      return;
    }

    this.cartao.number = this.cartao.number.replace(/ /g, "");

    if (this.isCardValid()) {
      this.storage
        .get(CONSTANTS.STORAGE.USER)
        .then((user) => {
          console.log("USER: ", user);
          this.cardService
            .twoFactor(user.telefone, user.countryNumber)
            .then(async (response: any) => {
              console.log("shortCode: ", response);
              this.digitShortCode = true;
              this.closeModal();
              await this.openConfirm();
            })
            .catch((responseError: any) => {
              console.log(responseError);
            });
        })
        .catch((error) => {
          console.log("StorageError:", error);
        });
      this.disabled = false;
    } else {
      this.toastr.genericToast(
        "Atenção",
        "Preencha todos os campos obrigatórios.",
        "warning",
        "bottom"
      );
      this.cancelDigitShortCode();
      this.disabled = false;
    }
    this.disabled = false;
    console.log(this.disabled);
  }

  cancelDigitShortCode() {
    this.cartao.shortCode = "";
    this.digitShortCode = false;
  }

  isCardValid() {
    return (
      this.cartao.holder !== "" &&
      this.cartao.number !== "" &&
      this.cartao.expiration !== "" &&
      this.cartao.cvv !== "" &&
      this.cartao.brand !== "" &&
      // this.cartao.CardToken != '' &&
      this.cartao.cpf !== ""
    );
  }
  isNameValid(name: string) {
    return !!name
      ? !!name.match(/^[A-zÀ-ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-ÿ][A-zÀ-ÿ']+$/)
      : false;
  }

  saveCardInternal(card) {
    // this.atualizaValorCC();s
    this.montaObj(card);
    console.log("Card: ", this.cartao);
    this.storage
      .get(CONSTANTS.STORAGE.USER)
      .then((user) => {
        this.cardService
          .saveCard(user.login_hash, this.cartao)
          .then((saveCardResponse) => {
            console.log("saveCardResponse", saveCardResponse);
            if (saveCardResponse.status) {
              this.toastr.genericToast(
                "Cartão adicionado!",
                "Cartão adicionado com sucesso.",
                "success",
                "bottom"
              );
              this.cartao.CardToken = saveCardResponse.payment_method.card.id;
              this.cancelDigitShortCode();
            }
          })
          .catch((error) => {
            console.log("SaveCardError: ", error);
            if (error.error.message === "Limite de cartões") {
              this.toastr.genericToast(
                "Limite de cartões atingido, para cadastrar delete um dos cartões",
                "Limite atingido.",
                "error",
                "bottom"
              );
            } else {
              this.toastr.genericToast(
                "Dados do cartão de crédito inválidos.",
                "Atenção",
                "error",
                "bottom"
              );
            }
            this.cartao = {
              holder: "",
              number: "",
              expiration: "",
              CardToken: "",
              brand: "",
              cvv: "",
              cpf: "",
            };
            this.cancelDigitShortCode();
          });
      })
      .catch((error) => {
        console.log("StorageError:", error);
        this.cancelDigitShortCode();
      });
  }

  async openShoppingCart() {
    const modal = await this.modalCtrl.create({
      component: CreditCardPage,
      cssClass: "longModal",
      componentProps: {
        closeModal: () => {
          modal.dismiss();
        },
      },
    });

    await modal.present();
    const { data, role } = await modal.onWillDismiss();
  }

  ngOnInit() {
    console.log(this.cardBrand);
  }
}
