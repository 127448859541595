import { RestaurantServiceService } from "src/app/services/restaurant.service.service";
import { Router } from "@angular/router";
import { EventsService } from "./../../services/events.service";
import { StorageService } from "./../../services/storage.service";
import { AdressService } from "./../../services/adress.service";
import {ModalController, NavController} from "@ionic/angular";
import {
  AfterViewInit,
  Component,
  ElementRef, Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { SearchAddressService } from "src/app/services/search-adress.service";
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { GLOBALS } from "src/app/globals";
import { Route } from "@angular/router";

declare var google;

@Component({
  selector: "app-new-adress",
  templateUrl: "./new-adress.page.html",
  styleUrls: ["./new-adress.page.scss"],
})
export class NewAdressPage implements OnInit, AfterViewInit {
  @ViewChild("map", { static: false }) mapElement: ElementRef;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;

  newAccount: boolean = false;

  adressList: any = [{}];
  hash: any;
  checkBox: boolean = false;
  selectedAddress: any;
  modaldeleteAdressOpen: boolean;
  endereco: any;

  public hasRua = false;
  public hasCep = false;
  public hasComplemento = false;
  public hasBairro = false;
  public hasNumero = false;
  public hasReferencia = false;

  public closeResult: any;
  public cart: any = "";
  public formattedaddress = "";
  public user: any;
  public creating: any;
  public addressComponent: any;
  public enderecoComponent: any;
  public disabled: boolean = false;
  public addError: any = "";
  public coordenadas: any;
  public step: number = 1;

  public map: any;
  public marker: any;

  public address = {
    cep: "",
    rua: "",
    numero: "",
    bairro: "",
    latitude: "",
    longitude: "",
    complemento: "",
    referencia: "",
    codCidade: "",
    cidade: "",
    uf: "",
  };

  step0: boolean;
  step1: boolean;
  step2: boolean;

  constructor(
    private nav: NavController,
    private addressService: AdressService,
    private searchAddress: SearchAddressService,
    private storageService: StorageService,
    private events: EventsService,
    private addressListServiceModule: AdressService,
    private restaurantService: RestaurantServiceService,
    private route: Router,
    private modalCtrl: ModalController
  ) {
    // this.step1 = true;
  }
  

  public options: any = {
    componentRestrictions: {
      country: ["BR"],
    },
  };

  ngOnInit() {
    this.initMap("-28.2486372", "-52.4256602");
    this.user = JSON.parse(localStorage.getItem("USER")!);

    console.log('Step: ', this.step);
    if (this.step === 0) {
      this.addressService
          .listAddress(this.user.login_hash)
          .then((res) => {
            this.adressList = res;
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          });
    }
  }

  ngAfterViewInit(): void {
    this.initMap("-28.2486372", "-52.4256602");
  }

  initMap(latitude, longitude) {
    setTimeout(() => {
      const position = new google.maps.LatLng(latitude, longitude);
      this.map = new google.maps.Map(this.mapElement.nativeElement, {
        zoom: 15,
        center: position,
        disableDefaultUI: true,
        gestureHandling: "block",
        mapTypeId: google.maps.MapTypeId.ROADMAP,
      });

      this.marker = new google.maps.Marker({
        map: this.map,
        position: position,
        animation: google.maps.Animation.DROP,
      });
    }, 500);
  }

  public async inserirEndereco() {
    this.disabled = true;
    this.user = JSON.parse(localStorage.getItem("USER")!);

    let postEnd = {
      login_hash: !!this.user ? this.user.login_hash : "",
      rua: this.address.rua,
      cep: this.address.cep,
      complemento: this.address.complemento,
      bairro: this.address.bairro,
      cidade: this.address.cidade,
      ativo: 1,
      numero: this.address.numero,
      codCidade: this.address.codCidade,
      latitude: this.address.latitude,
      longitude: this.address.longitude,
      referencia: this.address.referencia,
    };

    await this.addressService
      .getCityForName(
        this.searchAddress.getCidade(this.addressComponent),
        this.searchAddress.getUf(this.addressComponent)
      )
      .then((resp: any) => {
        postEnd.codCidade = resp.response.codCidade;
      })
      .catch((error) => {
        this.addError = true;
        return false;
      });

    if (this.addError) {
      this.disabled = false;
      return;
    }

    
    let login = !this.user ? "" : this.user.login_hash;
    let creating = this.creating;

    this.restaurantService
      .getStoreCity(postEnd.codCidade)
      .then((resp) => {
        // console.log('%cInicio verifica cidade', 'color: red; font-size: 18px');
        if (resp.ativo === "0" || resp.ativoApp === "0") {
          // this.erroRegiao();
          this.disabled = false;
          // console.log('%cCidade inativa', 'color: red; font-size: 18px');

          return;
        } else {
          if (login) {
            this.addressService
              .registerAddress(
                postEnd.login_hash,
                postEnd.rua,
                postEnd.cep,
                postEnd.complemento,
                postEnd.bairro,
                postEnd.cidade,
                postEnd.ativo,
                postEnd.numero,
                postEnd.codCidade,
                postEnd.latitude,
                postEnd.longitude,
                postEnd.referencia
              )
              .then((resp: any) => {
                // console.log('%c Resp registerAddress', 'color: blue;');
                // console.log(resp);
                //
                // console.log('%c GLOBAL URL SET', 'color:white');
                // console.log(GLOBALS.STORE_URL.urlSet)

                let login_hash = this.user.login_hash;

                this.addressListServiceModule
                  .listAddress(login_hash)
                  .then((resp: any) => {
                    localStorage.setItem("ADDRESS", JSON.stringify(resp));

                    //PROCURA PELO ENDEREÇO ATIVO
                    resp.map(function (address: any) {
                      if (address.ativo == 1) {
                        localStorage.setItem(
                          "ADDRESS_DEFAULT",
                          JSON.stringify(address)
                        );
                      }
                    });

                    let cart: any = null;
                    this.storageService.set("cart", cart).then((resp) => {
                      this.events.emit("cartUpdate", resp);
                    });

                    this.events.emit("enderecoUpdateListagemEnderecos");
                    this.events.emit("enderecoTratarStorageNavBar");

                    this.disabled = false;

                    // @ts-ignore
                    if (creating) {
                      // console.log("Inside creating");
                      localStorage.removeItem("creating");
                      this.events.emit("closeModalLogin");
                      this.events.emit("addressAdded");
                      this.events.emit("enderecoTratarStorageNavBar");
                      // this.events.emit('updateHome');
                    } else {
                      /**
                       * Se voltar a bugar desenvolver tratarRedirect + global pra verificar na home.
                       */

                      // console.log('%cNOT CREATING, HAS LOGIN:: ', 'color: red');
                      // console.log('%cSTORE URL SET:: ', 'color: white');
                      // console.log(GLOBALS.STORE_URL.urlSet);
                      // console.log(GLOBALS.STORE_URL.url);
                      this.events.emit("closeModalLogin");
                      // console.log(localStorage.getItem('ADDRESS_DEFAULT'));
                      this.events.emit("enderecoTratarStorageNavBar");

                      this.route.navigate(['/home']).then(() => {
                        // console.log('%cUPDATE HOME MAPS', 'color: green; font-size:20px');
                        this.events.emit('updateHome');
                      })
                    }
                  })
                  .catch((error: any) => {
                    // btn.setAttribute('style', 'disabled: false');
                    // console.log(error);
                  });
                // }
                return;
              })
              .catch((error: any) => {
                // btn.setAttribute('style', 'disabled: false');
                this.disabled = false;
                return;
              })
              .finally(() => {
                this.events.emit("closeModalAddress");
                this.address.rua = "";
                this.address.bairro = "";
                this.address.cep = "";
                this.address.complemento = "";
                this.address.numero = "";
                return;
              });
          } else {
            this.storageService
              .set("ADDRESS_PREMADE", postEnd)
              .then((resp) => {
                if (GLOBALS.STORE_URL.urlSet) {
                  // console.log({resp});
                  this.events.emit("setStorage", resp);
                  this.events.emit("enderecoTratarStorageNavBar");
                  this.disabled = false;
                  // return
                }
                // console.log("SET PREMADE");
                this.events.emit("enderecoTratarStorageNavBar");
                this.events.emit("closeModalAddress");
                this.modalCtrl.dismiss()
                // this.events.emit('updateHome');
                this.disabled = false;
                // this.modalService.dismissAll();
                // return;
              })
              .then(() => {
                if (GLOBALS.STORE_URL.urlSet) {
                  this.events.emit("closeAvisoLoja");
                }

                this.route.navigate(['/home']).then(() => {
                  this.events.emit('updateHome');
                });
              })
              .catch((error) => {
                // console.log(error);
                this.disabled = false;
                // btn.style.disabled = false;
              });
          }
        }
      })
      .catch((error) => {
        // console.log('Erro verifica cidade', error);
        return;
      });
  }

  selectAdress() {
    this.nav.navigateForward("select-adress");
  }

  toStep2() {
    // this.step2 = true;
    // this.step1 = false;
    this.step = 2;
  }

  stepNewAddress() {
    this.step = 1;
  }

  public AddressChange(address: any) {
    console.log(address);
    this.toStep2();

    // console.log('Address:: ', address);
    // console.log(' Latitude  ', address.geometry.location.lat());
    // console.log(' Longitude  ', address.geometry.location.lng());

    this.formattedaddress = address.formatted_address;
    this.addressComponent = address.address_components;

    this.address.rua = this.searchAddress.getEndereco(this.addressComponent);
    this.address.numero = this.searchAddress.getNumero(this.addressComponent);
    this.address.cep = this.searchAddress.getCEP(this.addressComponent);
    this.address.bairro = this.searchAddress.getBairro(this.addressComponent);
    this.address.cidade = this.searchAddress.getCidade(this.addressComponent);
    this.address.uf = this.searchAddress.getUf(this.addressComponent);
    this.address.latitude = address.geometry.location.lat();
    this.address.longitude = address.geometry.location.lng();

    this.hasRua = this.address.rua != "";
    this.hasCep = this.address.cep != "";
    this.hasComplemento = this.address.complemento != "";
    this.hasBairro = this.address.bairro != "";
    this.hasNumero = this.address.numero != "";
    this.hasReferencia = this.address.referencia != "";

    this.addressService
      .getCityForName(this.address.cidade, this.address.uf)
      .then((resp: any) => {
        this.address.codCidade = resp.response.codCidade;
        // console.log('ADDRESS: ', this.address);
      })
      .catch((error) => {
        this.addError = true;
        return false;
      });

    this.coordenadas = new google.maps.LatLng(
      this.address.latitude,
      this.address.longitude
    );

    this.initMap(this.address.latitude, this.address.longitude);

    let map = new google.maps.Map(this.map.nativeElement, {
      center: this.coordenadas.LatLng(),
      zoom: 16,
    });

    new google.maps.Circle({
      strokeColor: "#F37021",
      strokeOpacity: 0.5,
      strokeWeight: 2,
      fillColor: "#F37021",
      fillOpacity: 0.1,
      map: map,
      center: this.coordenadas,
      radius: 500,
    });

    map.addListener("dragend", () => {
      const location = map.getCenter();
      // console.log(location);
      if (
        this.searchAddress.getDistanceInMeters(this.coordenadas, location) > 500
      ) {
        map.panTo(this.coordenadas);
        this.marker.setMap(null);
        this.marker = new google.maps.Marker({
          map: map,
          position: this.coordenadas,
          animation: google.maps.Animation.DROP,
        });
      } else {
        this.address.latitude = location.lat();
        this.address.longitude = location.lng();
        this.marker.setMap(null);
        this.marker = new google.maps.Marker({
          map: map,
          position: location,
          animation: google.maps.Animation.DROP,
        });
      }
    });

    this.marker = new google.maps.Marker({
      map: map,
      position: this.coordenadas,
      animation: google.maps.Animation.DROP,
    });
  }

  goToLogin() {
    this.modalCtrl.dismiss();
    this.nav.navigateRoot('login');
  }

  clickActive(sequence) {
    this.addressService
        .enderecoAtivo(this.hash.login_hash, sequence)
        .then((res) => {
          console.log(res);
          localStorage.setItem('ADDRESS_DEFAULT', JSON.stringify(res));
          this.events.emit('updateHome');
          // location.reload();
        })
        .catch((err) => {
          console.log(err);
          console.log(encodeURIComponent(this.hash.login_hash));
        });
  }

  removeAdress(sequence) {
    console.log(sequence);

    this.addressService
        .removerEndereco(this.hash.login_hash, sequence)
        .then((res) => {
          console.log(res);
          this.modaldeleteAdressOpen = false
          location.reload()
        })
        .catch((err) => {
          console.log(err);
        });
  }

  modalDelete(endereco){
    this.modaldeleteAdressOpen = !this.modaldeleteAdressOpen;
    this.endereco = endereco;
    console.log(endereco);

  }

  addAdress() {
    this.step = 1;
  }
}
