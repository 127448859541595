import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "../constants";

@Injectable({
  providedIn: "root",
})
export class CardService {
  private headers = new HttpHeaders().set(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  constructor(private http: HttpClient) {}

  saveCard(login_hash: any, card: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.CREATE_CARD}`;
      const body = {
        login_hash: login_hash,
        holder: card.holder,
        number: card.number,
        expiration: card.expiration,
        token: card.CardToken,
        brand: card.brand,
        cvv: card.cvv,
        cpf: card.cpf,
        shortCode: card.shortCode,
      };
      console.log(url, body);
      this.http.post(url, body, { headers: this.headers }).subscribe(
        (response) => {
          return resolve(response);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }

  twoFactor(phone: any, countryNumber: any) {
    return new Promise((resolve, reject) => {
      const url = CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.TWO_FACTOR;
      const body = {
        phone: phone.replace(/([()])|-| /g, ""),
        countryNumber: countryNumber,
      };
      this.http.post(url, body, { headers: this.headers }).subscribe(
        (resp) => {
          return resolve(resp);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }

  getCartao(login_hash: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      params.append('login_hash', login_hash);
      const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.GET_CARTAO}?${params.toString()}`;
      this.http.get(url, {headers: this.headers}).subscribe((response) => {
        return resolve(response);
      }, (error) => {
        return reject(error);
      });
    });
  }
}
