import { ProductService } from "./../../services/product.service";
import { ActivatedRoute } from "@angular/router";
import { StorageService } from "./../../services/storage.service";
import { Component, Input, OnInit, Renderer2 } from "@angular/core";
import { CONSTANTS } from "src/app/constants";
import { NavParams } from "@ionic/angular";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  @Input() restaurant: any;
  produtoDetail: any;
  imageUrl = CONSTANTS.CONFIG.IMG_URL;
  valorProd: string;
  qtdProd: any;
  semProd: boolean;
  itemVariacaoArray: any;
  codJsonItemVariacao: any;
  currency: any;
  cor: any;
  logo: any;

  constructor(
    private storage: StorageService,
    private navParams: NavParams,
    private renderer: Renderer2
  ) {
    this.produtoDetail = navParams.get("produtoDetail");
    console.log(this.produtoDetail);
    storage.get("Afiliado").then((res: any) => {
      this.logo = CONSTANTS.CONFIG.IMG_URL + res.logo;
      console.log(this.logo);

      this.cor = res.cor;
    });

  }

  ngOnInit() {}

  public getMoeda() {
    this.storage.get("ADDRESS_DEFAULT").then((resp: any) => {
      if (!!resp?.jsonPais) {
        this.currency = resp.jsonPais.moeda;
      } else {
        this.currency = "R$";
      }
    });
  }

  updateTotal() {
    this.valorProd = (
      parseFloat(this.produtoDetail.valorDesconto) *
      parseFloat(this.qtdProd.toString())
    ).toFixed(2);
  }

  public addItem(event: any) {
    event.stopPropagation();
    const qtDisponivel = this.produtoDetail.qtDisponivel;

    if (
      qtDisponivel === null ||
      (qtDisponivel && this.qtdProd < qtDisponivel)
    ) {
      this.somaQtdProd(+1);
    }

    // this.qtdProd++;
    // this.valorProd = this.produtoDetail.valor * this.qtdProd;
  }

  public checkLimiteProd() {
    const qtDisponivel = this.produtoDetail.qtDisponivel;
    this.semProd = !!(qtDisponivel && this.qtdProd == qtDisponivel);
  }

  public somaQtdProd(qtd: any) {
    let qtdAtual = parseInt(this.qtdProd.toString());
    qtdAtual += parseInt(qtd);
    if (qtdAtual <= 0) {
      qtdAtual = 1;
    }
    this.qtdProd = qtdAtual;
    this.updateTotal();
    this.checkLimiteProd();
  }

  public tratarCliqueVariacao(itemVariacao: any, variacao: any, event: any) {
    event.stopPropagation();

    if (parseInt(variacao.qtdMax) === 1) {
      // console.log('caiu no checkbox');
      this.onClickVariacao(variacao, itemVariacao, event);
    } else {
      // console.log('caiu no addVariacao');
      this.addVariacao(itemVariacao, variacao, event);
    }
  }

  // Variacao múltipla
  public addVariacao(itemVariacao: any, variacao: any, event: any) {
    event.stopPropagation();

    if (variacao.qtdSel >= variacao.qtdMax) {
      return;
    }

    // index das variacoes
    const indexItem = this.produtoDetail.variacaoJSON.findIndex((item: any) => {
      return item === variacao;
    });

    // index dos itens de cada variacao
    const indexItemVariacao = this.produtoDetail.variacaoJSON[
      indexItem
    ].ItemProdutoVariacao.findIndex((item: any) => {
      return item === itemVariacao;
    });

    // console.log({indexItem});
    // console.log({indexItemVariacao})

    // obj p/ itemVariacaoArray
    const objVariacao = {
      codProdutoVariacao:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].codProdutoVariacao,
      descVariacao: this.produtoDetail.variacaoJSON[indexItem].descricao,
      descricao:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].jsonProduto[0].descricao,
      sequencia:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].sequencia,
      valor:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].valor,
    };

    let auxMaiorValor = 0;

    for (let i = 0; i < this.itemVariacaoArray.length; i++) {
      if (
        this.itemVariacaoArray[i].codProdutoVariacao ===
        objVariacao.codProdutoVariacao
      ) {
        if (auxMaiorValor < parseFloat(this.itemVariacaoArray[i].valor)) {
          auxMaiorValor = parseFloat(this.itemVariacaoArray[i].valor);
        }
      }
    }

    // console.log("Item Variacao ARRAY: ", this.itemVariacaoArray);

    // Qtd de variacao selecionada
    if (
      this.produtoDetail.variacaoJSON[indexItem].qtdSel == null ||
      isNaN(this.produtoDetail.variacaoJSON[indexItem].qtdSel)
    ) {
      this.produtoDetail.variacaoJSON[indexItem].qtdSel = 1;
    } else {
      this.produtoDetail.variacaoJSON[indexItem].qtdSel++;
    }

    this.itemVariacaoArray.push(objVariacao);

    switch (this.produtoDetail.variacaoJSON[indexItem].tipoCobranca) {
      case "n": // Soma normal
        // console.log("Soma normal valor desc: ", this.produtoDetail);
        // console.log("Obj var valor: ", objVariacao.valor);
        this.produtoDetail.valorDesconto =
          parseFloat(objVariacao.valor) +
          parseFloat(this.produtoDetail.valorDesconto);

        break;
      case "m": // Maior Valor
        let maiorValor = 0;
        let qtdSelPrincipal = 0;

        for (let i = 0; i < this.itemVariacaoArray.length; i++) {
          if (
            parseInt(this.itemVariacaoArray[i].codProdutoVariacao) ===
            parseInt(objVariacao.codProdutoVariacao)
          ) {
            if (maiorValor < parseFloat(this.itemVariacaoArray[i].valor)) {
              maiorValor = parseFloat(this.itemVariacaoArray[i].valor);
            }
            qtdSelPrincipal++;
          }
        }
        if (qtdSelPrincipal > 1) {
          this.produtoDetail.valorDesconto =
            parseFloat(this.produtoDetail.valorDesconto) - auxMaiorValor;
        }
        this.produtoDetail.valorDesconto =
          parseFloat(this.produtoDetail.valorDesconto) + maiorValor;
        break;
      case "g": // Media Geral
        let qtd = 0;
        let totalVariacao = 0;
        let qtdAntiga = 0;
        let mediaAntiga = 0;

        for (let i = 0; i < this.itemVariacaoArray.length; i++) {
          if (
            parseInt(this.itemVariacaoArray[i].codProdutoVariacao) ===
            parseInt(objVariacao.codProdutoVariacao)
          ) {
            qtd++;
            totalVariacao += parseFloat(this.itemVariacaoArray[i].valor);
            // console.log({qtd});
            if (objVariacao != this.itemVariacaoArray[i]) {
              qtdAntiga++;
              // console.log({qtdAntiga});
              mediaAntiga += parseFloat(this.itemVariacaoArray[i].valor);
            }
          }
        }

        if (qtdAntiga > 0) {
          mediaAntiga = mediaAntiga / qtdAntiga;
          // console.log({mediaAntiga});
        }

        totalVariacao = totalVariacao / qtd;
        // console.log({totalVariacao});
        this.produtoDetail.valorDesconto =
          totalVariacao +
          (parseFloat(this.produtoDetail.valorDesconto) - mediaAntiga);
        break;
      default:
        break;
    }

    this.updateTotal();

    this.codJsonItemVariacao = this.itemVariacaoArray.map((item: any) => {
      return parseInt(item.sequencia);
    });
  }

  checkVariacao(itemVariacao: any) {
    return this.codJsonItemVariacao.filter(
      (item: any) => parseInt(item) === parseInt(itemVariacao.sequencia)
    ).length;
  }

  // - - - - - - - - - - - - - - - - - - - Variacao checkbox - - - - - - - - - - - - - - - - - - - //
  public addVariacaoCheckbox(event: any, itemVariacao: any, variacao: any) {
    const indexItem = this.produtoDetail.variacaoJSON.findIndex((item: any) => {
      return item === variacao;
    });

    const indexItemVariacao = this.produtoDetail.variacaoJSON[
      indexItem
    ].ItemProdutoVariacao.findIndex((item: any) => {
      return item === itemVariacao;
    });

    if (event === null) {
      itemVariacao.checked = !itemVariacao.checked;
      event = {
        checked: itemVariacao.checked,
      };
    }

    const objVariacao = {
      codProdutoVariacao:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].codProdutoVariacao,
      descVariacao: this.produtoDetail.variacaoJSON[indexItem].descricao,
      descricao:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].jsonProduto[0].descricao,
      sequencia:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].sequencia,
      valor:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].valor,
    };

    let auxMaiorValor = 0;
    for (let index = 0; index < this.itemVariacaoArray.length; index++) {
      if (
        this.itemVariacaoArray[index].codProdutoVariacao ===
        objVariacao.codProdutoVariacao
      ) {
        if (auxMaiorValor < parseFloat(this.itemVariacaoArray[index].valor)) {
          auxMaiorValor = parseFloat(this.itemVariacaoArray[index].valor);
        }
      }
    }

    if (event.checked) {
      if (
        this.produtoDetail.variacaoJSON[indexItem].qtdSel == null ||
        isNaN(this.produtoDetail.variacaoJSON[indexItem].qtdSel)
      ) {
        this.produtoDetail.variacaoJSON[indexItem].qtdSel = 1;
        // console.log("ta checkado");
        // console.log(this.produtoDetail.variacaoJSON[indexItem]);
      } else {
        this.produtoDetail.variacaoJSON[indexItem].qtdSel += 1;
      }

      let auxMaiorValor = 0;
      for (let i = 0; i < this.itemVariacaoArray.length; i++) {
        if (
          this.itemVariacaoArray[i].codProdutoVariacao ===
          objVariacao.codProdutoVariacao
        ) {
          if (auxMaiorValor < parseFloat(this.itemVariacaoArray[i].valor)) {
            auxMaiorValor = parseFloat(this.itemVariacaoArray[i].valor);
          }
        }
      }

      this.itemVariacaoArray.push(objVariacao);

      switch (this.produtoDetail.variacaoJSON[indexItem].tipoCobranca) {
        case "n":
          this.produtoDetail.valorDesconto =
            parseFloat(objVariacao.valor) +
            parseFloat(this.produtoDetail.valorDesconto);
          break;
        case "m":
          let maiorValor = 0;
          let qtdselPrincipal = 0;

          for (let i = 0; i < this.itemVariacaoArray.length; i++) {
            if (
              this.itemVariacaoArray[i].codProdutoVariacao ===
              objVariacao.codProdutoVariacao
            ) {
              if (maiorValor < parseFloat(this.itemVariacaoArray[i].valor)) {
                maiorValor = parseFloat(this.itemVariacaoArray[i].valor);
              }
              qtdselPrincipal++;
            }
          }

          if (qtdselPrincipal > 1) {
            this.produtoDetail.valorDesconto =
              parseFloat(this.produtoDetail.valorDesconto) - auxMaiorValor;
          }

          this.produtoDetail.valorDesconto =
            parseFloat(this.produtoDetail.valorDesconto) + maiorValor;
          break;
        case "g":
          let qtd = 0;
          let totalVariacao = 0;
          let qtdAntiga = 0;
          let mediaAntiga = 0;

          for (let i = 0; i < this.itemVariacaoArray[i].length; i++) {
            if (
              this.itemVariacaoArray[i].codProdutoVariacao ===
              objVariacao.codProdutoVariacao
            ) {
              qtd++;
              // console.log({qtd});
              totalVariacao += parseFloat(this.itemVariacaoArray[i].valor);
              if (objVariacao !== this.itemVariacaoArray[i]) {
                qtdAntiga++;
                // console.log({qtdAntiga});
                mediaAntiga += parseFloat(this.itemVariacaoArray[i].valor);
              }
            }
          }

          if (qtdAntiga > 0) {
            mediaAntiga = mediaAntiga / qtdAntiga;
            // console.log({mediaAntiga});
          }

          totalVariacao = totalVariacao / qtd;
          // console.log({totalVariacao});

          this.produtoDetail.valorDesconto =
            totalVariacao +
            (parseFloat(this.produtoDetail.valorDesconto) - mediaAntiga);
          break;
        default:
          break;
      }

      this.updateTotal();
    } else {
      this.produtoDetail.variacaoJSON[indexItem].qtdSel -= 1;

      const indexRemover = this.removeVariacao(objVariacao);

      let auxMaiorValor = 0;
      for (let i = 0; i < this.itemVariacaoArray.length; i++) {
        if (
          this.itemVariacaoArray[i].codProdutoVariacao ===
          objVariacao.codProdutoVariacao
        ) {
          if (auxMaiorValor < parseFloat(this.itemVariacaoArray[i].valor)) {
            auxMaiorValor = parseFloat(this.itemVariacaoArray[i].valor);
          }
        }
      }

      if (this.itemVariacaoArray.length > 0) {
        switch (this.produtoDetail.variacaoJSON[indexItem].tipoCobranca) {
          case "n":
            let maiorValor = 0;
            let qtdSelPrincipal = 0;
            this.itemVariacaoArray.splice(indexRemover, 1);
            for (let i = 0; i < this.itemVariacaoArray.length; i++) {
              if (
                this.itemVariacaoArray[i].codProdutoVariacao ===
                objVariacao.codProdutoVariacao
              ) {
                if (maiorValor < parseFloat(this.itemVariacaoArray[i].valor)) {
                  maiorValor = parseFloat(this.itemVariacaoArray[i].valor);
                }
                qtdSelPrincipal++;
              }
            }

            if (auxMaiorValor > maiorValor) {
              this.produtoDetail.valorDesconto =
                parseFloat(this.produtoDetail.valorDesconto) - auxMaiorValor;
              this.produtoDetail.valorDesconto =
                parseFloat(this.produtoDetail.valorDesconto) + maiorValor;
            }

            break;
          case "g":
            let qtd = 0;
            let totalVariacao = 0;
            let qtdAntiga = 0;
            let mediaAntiga = 0;

            for (let i = 0; i < this.itemVariacaoArray.length; i++) {
              if (
                this.itemVariacaoArray[i].codProdutoVariacao ===
                objVariacao.codProdutoVariacao
              ) {
                qtdAntiga++;
                // console.log({qtdAntiga});
                mediaAntiga += parseFloat(this.itemVariacaoArray[i].valor);
              }
            }
            this.itemVariacaoArray.splice(indexRemover, 1);

            for (let i = 0; i < this.itemVariacaoArray.length; i++) {
              if (
                this.itemVariacaoArray[i].codProdutoVariacao ===
                objVariacao.codProdutoVariacao
              ) {
                qtd++;
                // console.log({qtd});
                totalVariacao += parseFloat(this.itemVariacaoArray[i].valor);
              }
            }

            if (qtdAntiga > 0) {
              mediaAntiga = mediaAntiga / qtdAntiga;
              // console.log({mediaAntiga});
            }

            if (totalVariacao > 0) {
              totalVariacao = totalVariacao / qtdAntiga;
              // console.log({totalVariacao});
            }

            this.produtoDetail.valorDesconto =
              parseFloat(this.produtoDetail.valorDesconto) - mediaAntiga;
            this.produtoDetail.valorDesconto =
              this.produtoDetail.valorDesconto + totalVariacao;
            break;
          default:
            break;
        }
      }

      if (this.produtoDetail.variacaoJSON[indexItem].tipoCobranca === "n") {
        this.itemVariacaoArray.splice(indexRemover, 1);
      }

      this.updateTotal();
    }
  }

  // @ts-ignore
  onClickVariacao(variacao: any, itemVariacao: any, event: any) {
    event.stopPropagation();
    // console.log(variacao);
    if (variacao.qtdMax <= 1) {
      if (variacao.qtdSel >= variacao.qtdMax && !itemVariacao.checked) {
        // console.log(event);
        // console.log("Stopped propagation");
        // console.log("qtdSel: ", JSON.parse(JSON.stringify(variacao.qtdSel)));
        event.stopPropagation();
        return false;
      } else {
        // console.log("OMG WHY UR HERE");
        this.addVariacaoCheckbox(null, itemVariacao, variacao);
      }
    }
  }

  removeVariacao(checkbox: any) {
    return this.itemVariacaoArray.findIndex(
      (x: any) => x.sequencia === checkbox.sequencia
    );
  }

  removeItemVariacao(itemVariacao: any, variacao: any, event: any) {
    event.stopPropagation();
    // console.log('Remove item');
    const indexItem = this.produtoDetail.variacaoJSON.findIndex((item: any) => {
      return item === variacao;
    });

    const indexItemVariacao = this.produtoDetail.variacaoJSON[
      indexItem
    ].ItemProdutoVariacao.findIndex((item: any) => {
      return item === itemVariacao;
    });
    // console.log({indexItem});
    // console.log({indexItemVariacao});

    const objVariacao = {
      codProdutoVariacao:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].codProdutoVariacao,
      descVariacao: this.produtoDetail.variacaoJSON[indexItem].descricao,
      descricao:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].jsonProduto[0].descricao,
      sequencia:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].sequencia,
      valor:
        this.produtoDetail.variacaoJSON[indexItem].ItemProdutoVariacao[
          indexItemVariacao
        ].valor,
    };

    // console.log({objVariacao});
    let auxMaiorValor = 0;
    for (let i = 0; i < this.itemVariacaoArray.length; i++) {
      if (
        this.itemVariacaoArray[i].codProdutoVariacao ===
        objVariacao.codProdutoVariacao
      ) {
        if (auxMaiorValor < parseFloat(this.itemVariacaoArray[i].valor)) {
          auxMaiorValor = parseFloat(this.itemVariacaoArray[i].valor);
        }
      }
    }

    this.produtoDetail.variacaoJSON[indexItem].qtdSel -= 1;

    const indexRemover = this.removeVariacao(objVariacao);

    if (this.itemVariacaoArray.length > 0) {
      switch (this.produtoDetail.variacaoJSON[indexItem].tipoCobranca) {
        case "n":
          this.produtoDetail.valorDesconto =
            parseFloat(this.produtoDetail.valorDesconto) -
            parseFloat(itemVariacao.valor);
          break;
        case "m":
          let maiorValor = 0;
          this.itemVariacaoArray.splice(indexRemover, 1);
          for (let i = 0; i < this.itemVariacaoArray.length; i++) {
            if (
              this.itemVariacaoArray[i].codProdutoVariacao ===
              objVariacao.codProdutoVariacao
            ) {
              if (maiorValor < parseFloat(this.itemVariacaoArray[i].valor)) {
                maiorValor = parseFloat(this.itemVariacaoArray[i].valor);
              }
            }
          }

          if (auxMaiorValor > maiorValor) {
            this.produtoDetail.valorDesconto =
              parseFloat(this.produtoDetail.valorDesconto) - auxMaiorValor;
            this.produtoDetail.valorDesconto =
              parseFloat(this.produtoDetail.valorDesconto) + maiorValor;
          }

          break;
        case "g":
          let qtd = 0;
          let totalVariacao = 0;
          let qtdAntiga = 0;
          let mediaAntiga = 0;

          for (let i = 0; i < this.itemVariacaoArray.length; i++) {
            if (
              this.itemVariacaoArray[i].codProdutoVariacao ===
              objVariacao.codProdutoVariacao
            ) {
              qtdAntiga++;
              // console.log({qtdAntiga});
              mediaAntiga += parseFloat(this.itemVariacaoArray[i].valor);
            }
          }
          this.itemVariacaoArray.splice(indexRemover, 1);

          for (let i = 0; i < this.itemVariacaoArray.length; i++) {
            if (
              this.itemVariacaoArray[i].codProdutoVariacao ===
              objVariacao.codProdutoVariacao
            ) {
              qtd++;
              // console.log({qtd});
              totalVariacao += parseFloat(this.itemVariacaoArray[i].valor);
              // console.log({totalVariacao});
            }
          }

          if (qtdAntiga > 0) {
            mediaAntiga = mediaAntiga / qtdAntiga;
            // console.log({mediaAntiga});
          }

          if (totalVariacao > 0) {
            totalVariacao = totalVariacao / qtd;
            // console.log({totalVariacao});
          }

          this.produtoDetail.valorDesconto =
            parseFloat(this.produtoDetail.valorDesconto) - mediaAntiga;
          this.produtoDetail.valorDesconto =
            this.produtoDetail.valorDesconto + totalVariacao;
          break;
        default:
          break;
      }
    }

    if (this.produtoDetail.variacaoJSON[indexItem].tipoCobranca === "n") {
      this.itemVariacaoArray.splice(indexRemover, 1);
    }

    this.updateTotal();

    this.codJsonItemVariacao = this.itemVariacaoArray.map((item: any) => {
      return parseInt(item.sequencia);
    });
  }
}
