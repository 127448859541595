import { Component, Input, OnInit } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { CONSTANTS } from "src/app/constants";
import { HomeService } from "src/app/services/home.service";
import { StorageService } from "src/app/services/storage.service";

@Component({
  selector: "app-header2",
  templateUrl: "./header2.component.html",
  styleUrls: ["./header2.component.scss"],
})
export class Header2Component implements OnInit {
  @Input() pageName: string;
  @Input() route: string;
  @Input() modal: string;
  @Input() closeModal: () => void;
  logo: any;
  cor: any;

  constructor(
    private nav: NavController,
    private modalCtrl: ModalController,
    private storage: StorageService,
    private homeService: HomeService
  ) {
    storage.get("Afiliado").then((res: any) => {
      this.logo = CONSTANTS.CONFIG.IMG_URL + res.logo;
      console.log(this.logo);

      this.cor = res.cor;
    });
  }

  ngOnInit() {}

  home(route) {
    if (!!this.modal && this.modal === "true") {
      this.modalCtrl.dismiss();
    } else {
      if (route === "" || route === null || route === undefined) {
        if (!!this.closeModal) {
          this.closeModal();
        } else {
          this.nav.navigateBack("tabs/home");
        }
      }
      this.nav.navigateBack(route);
    }
  }
}
