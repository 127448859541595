import { StorageService } from "src/app/services/storage.service";
import { Component, OnInit } from "@angular/core";
import { MenuController, ModalController, NavController } from "@ionic/angular";
import { SelectAdressPage } from "../../pages/select-adress/select-adress.page";
import { NewAdressPage } from "../../pages/new-adress/new-adress.page";
import { HomeService } from "src/app/services/home.service";
import { CONSTANTS } from "src/app/constants";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  rua: any;
  bairro: any;
  numero: any;
  logo: any;
  cor: any;

  constructor(
    private menu: MenuController,
    private nav: NavController,
    private storage: StorageService,
    private modalCtrl: ModalController,
    private homeService: HomeService
  ) {
    const addressDefault = this.storage.getSync("ADDRESS_DEFAULT");
    // const addressPremade = this.storage.getSync('ADDRESS_PREMADE');,

    if (addressDefault) {
      this.rua = addressDefault?.rua;
      this.bairro = addressDefault?.bairro;
      this.numero = addressDefault?.numero;
    }

    storage.get("Afiliado").then((res: any) => {
      this.logo = CONSTANTS.CONFIG.IMG_URL + res.logo;
      console.log(this.logo);

      this.cor = res.cor;
    });

    // this.storage.get("ADDRESS_DEFAULT").then((res) => {
    //   this.rua = res?.rua
    //   this.bairro = res?.bairro
    //   this.numero = res?.numero
    // }).catch((err) => {
    //   console.log(err);
    //
    // });
  }

  ngOnInit() {}

  pedidos() {
    this.nav.navigateForward("tabs/tab3");
  }

  home() {
    this.nav.navigateRoot("home");
  }

  address() {
    this.nav.navigateForward("select-adress");
  }

  async openAddresses() {
    const modal = await this.modalCtrl.create({
      component: NewAdressPage,
      cssClass: "address-modal",
      componentProps: {
        step: 0,
        newAccount: false,
      },
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();
  }
}
