import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "../constants";

@Injectable({
  providedIn: "root",
})
export class AdressService {
  constructor(private http: HttpClient) {}
  private headers = new HttpHeaders().set(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  listAddress(hash: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${
        CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.ENDERECO
      }?login_hash=${encodeURIComponent(hash)}`;
      this.http.get(url).subscribe(
        (resp) => {
          return resolve(resp);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }

  removerEndereco(hash: string, sequencia: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const body: any = {
        login_hash: hash,
        sequencia: sequencia,
      };
      const options = {
        headers: this.headers,
      };
      const url = CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.ADDRESSREMOVE;
      this.http.post(url, body, options).subscribe(
        (resp) => {
          return resolve(resp);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }

  registerAddress(
    login_hash: any,
    rua: any,
    cep: any,
    complemento: any,
    bairro: any,
    cidade: any,
    ativo: any,
    numero: any,
    codCidade: any,
    latitude: any,
    longitude: any,
    referencia: any
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      const body: any = {
        login_hash: login_hash,
        rua: rua,
        cep: cep,
        complemento: complemento,
        bairro: bairro,
        cidade: cidade,
        ativo: ativo,
        numero: numero,
        codCidade: codCidade,
        latitude: latitude,
        longitude: longitude,
        referencia: referencia,
      };
      const url =
        CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.REGISTER_ENDERECO;
      console.log({
        url,
        body,
      });
      this.http.post(url, body, { headers: this.headers }).subscribe(
        (resp) => {
          return resolve(resp);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }

  enderecoAtivo(login_hash: string, sequence: number): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.ATIVAR_ENDERECO}`;
      const body = {
        login_hash: login_hash,
        sequencia: sequence,
      };
      console.log(body);

      const options = {
        headers: this.headers,
      };
      this.http.post(url, body, options).subscribe(
        (resp) => {
          return resolve(resp);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }

  getCityForName(cidade: any, uf: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      params.append("cidade", cidade);
      params.append("uf", uf);
      const url = CONSTANTS.CONFIG.BASE_URL + "getCidadeByDescricao?" + params;
      console.log({
        url,
        params,
      });
      this.http.get(url, { headers: this.headers }).subscribe(
        (resp) => {
          return resolve(resp);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }
}
