export var GLOBALS = {

    FACEBOOK_LOGIN: {
      facebookSet: false,
      facebookObj: {
        email: '',
        facebook_id: '',
        name: '',
        photoUrl: ''
      }
    },
  
    STORE_URL: {
      urlSet: false,
      url: ''
    },
  
    SMS_LOGIN: {
      tel: ''
    }
  
  }
  