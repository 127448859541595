import { CupomComponent } from "./../components/cupom/cupom.component";

import { CreditCardComponent } from "./../components/credit-card/credit-card.component";
import { PaymentComponent } from "./../components/payment/payment.component";
import { OrderTrackingComponent } from "./../components/order-tracking/order-tracking.component";
import { DetailsComponent } from "./../components/details/details.component";
import { ProductComponent } from "./../components/product/product.component";

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HeaderComponent } from "src/app/components/header/header.component";
import { StoreCardComponent } from "../components/store-card/store-card.component";
import { IonicModule } from "@ionic/angular";
import { Header2Component } from "../components/header2/header2.component";
import { TabsPage } from "../tabs/tabs.page";
import { LoaderComponent } from "../components/loader/loader.component";
import { CreditCardFormatterPipe } from "../pipes/credit-card-formatter.pipe";
import { FormatterDateCcPipe } from "../pipes/formatter-date-cc.pipe";
import { SimpleMaskModule, SimpleMaskDirective } from "ngx-ion-simple-mask";
import { FormsModule } from "@angular/forms";

@NgModule({
  declarations: [
    HeaderComponent,
    StoreCardComponent,
    Header2Component,
    ProductComponent,
    LoaderComponent,
    DetailsComponent,
    CreditCardFormatterPipe,
    FormatterDateCcPipe,
    OrderTrackingComponent,
    PaymentComponent,
    CreditCardComponent,
    CupomComponent,
  ],
  imports: [CommonModule, IonicModule, FormsModule],
  exports: [
    HeaderComponent,
    StoreCardComponent,
    Header2Component,
    ProductComponent,
    LoaderComponent,
    DetailsComponent,
    CreditCardFormatterPipe,
    FormatterDateCcPipe,
    OrderTrackingComponent,
    PaymentComponent,
    CreditCardComponent,
    CupomComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedModule {}
