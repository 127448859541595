import {Component, Input, OnInit} from '@angular/core';
import { CONSTANTS } from 'src/app/constants';

@Component({
  selector: 'app-store-card',
  templateUrl: './store-card.component.html',
  styleUrls: ['./store-card.component.scss'],
})
export class StoreCardComponent implements OnInit {
  @Input() public store;

  imgUrl = CONSTANTS.CONFIG.IMG_URL
  constructor() { 
    
  }

  ngOnInit() {}

}
