import { CardService } from "src/app/services/card.service";
import { StorageService } from "./../../services/storage.service";
import { ModalController } from "@ionic/angular";
import { Component, Input, OnInit } from "@angular/core";
import { CreditCardPage } from "src/app/pages/credit-card/credit-card.page";
import { CONSTANTS } from "src/app/constants";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"],
})
export class PaymentComponent implements OnInit {
  @Input() closeModal: () => void;
  creditCard: boolean;
  cards: any = [];
  brandImage: any;
  logo: any;
  cor: any;
  constructor(
    private modalCtrl: ModalController,
    private cardService: CardService,
    private storage: StorageService
  ) {
    storage.get("Afiliado").then((res: any) => {
      this.logo = CONSTANTS.CONFIG.IMG_URL + res.logo;
      console.log(this.logo);

      this.cor = res.cor;
    });
  }

  ngOnInit() {}
  imgURL =
    "https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/";

  dinheiro() {
    this.modalCtrl.dismiss({
      idPagamento: 2,
      descricao: "Dinheiro",
    });
    this.storage.get("cart").then((res) => {
      res.descricaoPagamento = "Dinheiro";
      this.storage.set("cart", res).then((res) => {
        console.log("descricao concluida");
      });
    });
  }

  pix() {
    this.modalCtrl.dismiss({
      idPagamento: 3,
      descricao: "Pix",
    });
    this.storage.get("cart").then((res) => {
      res.descricaoPagamento = "Pix";
      this.storage.set("cart", res).then((res) => {
        console.log("descricao concluida");
      });
    });
  }

  creditCardOpt() {
    this.creditCard = true;
    this.getCard();
  }

  back() {
    this.creditCard = false;
  }

  getCard() {
    this.storage
      .get(CONSTANTS.STORAGE.USER)
      .then((res) => {
        this.cardService.getCartao(res.login_hash).then((res) => {
          console.log(res);
          this.cards = res;
          this.brandImage = this.imgURL + res.brand + ".png";
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async openCC() {
    const modal = await this.modalCtrl.create({
      component: CreditCardPage,
      cssClass: "modalCC",
      componentProps: {
        closeModal: () => {
          modal.dismiss();
        },
      },
    });
    await modal.present();

    const { data, role } = await modal.onWillDismiss();
  }

  selectCreditCard(card) {
    this.modalCtrl.dismiss({
      idPagamento: 1,
      descricao: "Cartão de crédito",
      imgUrl: this.imgURL + card.brand + ".png",
      card: card,
    });
    this.storage.get("cart").then((res) => {
      res.descricaoPagamento = "Cartão de crédito";
      this.storage.set("cart", res).then((res) => {
        console.log("descricao concluida");
      });
    });
  }
}
