import { PaymentPage } from './pages/payment/payment.page';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'order-details',
    loadChildren: () => import('./pages/order-details/order-details.module').then( m => m.OrderDetailsPageModule)
  },
  {
    path: 'order-tracking',
    loadChildren: () => import('./pages/order-tracking/order-tracking.module').then( m => m.OrderTrackingPageModule)
  },
  {
  path: 'shopping-cart',
  loadChildren: () => import('./pages/shopping-cart/shopping-cart.module').then(m => m.ShoppingCartPageModule)
  },
  {
    path: 'restaurant',
    loadChildren: () => import('./pages/restaurant/restaurant.module').then( m => m.RestaurantPageModule)
  },
  {
    path: 'product',
    loadChildren: () => import('./pages/product/product.module').then(m => m.ProductPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./pages/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'credit-card',
    loadChildren: () => import('./pages/credit-card/credit-card.module').then( m => m.CreditCardPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'email-login',
    loadChildren: () => import('./pages/email-login/email-login.module').then( m => m.EmailLoginPageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('./pages/shopping-cart/shopping-cart.module').then( m => m.ShoppingCartPageModule)
  },
  {
    path: 'user-edit/:text',
    loadChildren: () => import('./pages/user-edit/user-edit.module').then( m => m.UserEditPageModule)
  },
  {
    path: 'new-adress',
    loadChildren: () => import('./pages/new-adress/new-adress.module').then( m => m.NewAdressPageModule)
  },
  {
    path: 'select-adress',
    loadChildren: () => import('./pages/select-adress/select-adress.module').then( m => m.SelectAdressPageModule)
  },
  {
    path: 'confirm-adress',
    loadChildren: () => import('./pages/confirm-adress/confirm-adress.module').then( m => m.ConfirmAdressPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule)
  },
  {
    path: 'category',
    loadChildren: () => import('./pages/category/category.module').then( m => m.CategoryPageModule)
  },
  {
    path: 'promotion/:text',
    loadChildren: () => import('./pages/promotion/promotion.module').then( m => m.PromotionPageModule)
  },
  {
    path: 'favorites',
    loadChildren: () => import('./pages/favorites/favorites.module').then( m => m.FavoritesPageModule)
  },
  {
    path: 'terms-privacy',
    loadChildren: () => import('./pages/terms-privacy/terms-privacy.module').then( m => m.TermsPrivacyPageModule)
  },
  {
    path: 'cupom',
    loadChildren: () => import('./pages/cupom/cupom.module').then( m => m.CupomPageModule)
  },
  {
    path: 'help',
    loadChildren: () => import('./pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'account-create',
    loadChildren: () => import('./pages/account-create/account-create.module').then( m => m.AccountCreatePageModule)
  },
  {
    path: 'shopping-cart',
    loadChildren: () => import('./components/shopping-cart/shopping-cart.module').then( m => m.ShoppingCartPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
