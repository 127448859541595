import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "../constants";

@Injectable({
  providedIn: "root",
})
export class CupomService {
  private headers = new HttpHeaders().set(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  constructor(private http: HttpClient) {}

  getCupons(login_hash = "", codLoja = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      const params = new URLSearchParams();
      params.append("login_hash", login_hash);
      params.append("codLoja", codLoja);
      const url = `${
        CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.GET_CUPONS
      }?${params.toString()}`;
      this.http.get(url, { headers: this.headers }).subscribe(
        (response) => {
          return resolve(response);
        },
        (error) => {
          console.error("ERROR", error);
          return reject(error);
        }
      );
    });
  }

  buscarCupom(login_hash = "", codLoja = "", codigo = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.BUSCAR_CUPOM;
      const params = new URLSearchParams();
      params.append("login_hash", login_hash);
      params.append("codLoja", codLoja);
      params.append("codigo", codigo);
      this.http.get(`${url}?${params}`, { headers: this.headers }).subscribe(
        (response) => {
          return resolve(response);
        },
        (error) => {
          return reject(error);
        }
      );
    });
  }
}
