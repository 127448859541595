import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  private events: any[] = [];
  private logLevel = 0;

  constructor() {
  }

  public emit(event: any, params = {}) {
    if (this.logLevel === 1) {
      console.log('emit', event, params);
    }

    console.log('emit', event, params);
    if (!!this.events[event]) {
      // console.log('emit 2', event, params);
      this.events[event].subject.next(params);
    }
  }

  public unsubscribe(event: any) {
    if (this.logLevel === 1) {
      console.log('unsubscribe', event);
    }

    if (!!this.events[event]) {
      this.events[event] = null;
    }
  }

  public subscribe(event: any, callable: any) {
    if (this.logLevel === 1) {
      console.log('subscribe', event, callable);
    }

    // console.log('subscribe 2', event, callable);
    const subject = new Subject<any>();
    const observable = subject.asObservable();
    const subscription = observable.subscribe(callable);
    this.events[event] = {
      subject,
      observable,
      subscription,
    };
  }

  public setLogLevel(level: number) {
    this.logLevel = level;
  }
}
