import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  constructor() {}

  public set(key: string, content: any): Promise<any> {
    return new Promise((resolve, reject) => {
      content = JSON.stringify(content);
      localStorage.setItem(key, content);
      return resolve(JSON.parse(content));
    });
  }

  public get(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return resolve(JSON.parse(localStorage.getItem(key)!));
    });
  }

  public remove(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      return resolve(localStorage.removeItem(key));
    });
  }

  public getSync(key: string, json: boolean = true) {
    const content = localStorage.getItem(key)!;
    if (content == null) {
      return null;
    }
    return json ? JSON.parse(content) : content;
  }

  public setSync(key: string, content: string) {
    localStorage.setItem(key, content);
    return content;
  }
}
