import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "creditCardFormatter",
})
export class CreditCardFormatterPipe implements PipeTransform {
  transform(value: string): string {
    const formattedValue = value
      .replace(/\s+/g, "")
      .replace(/(\d{4})/g, "$1 ")
      .trim();
    return formattedValue;
  }
}
