import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { CONSTANTS } from "../constants";
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class LocationService {
  private headers = new HttpHeaders().set(
    "Content-Type",
    "application/x-www-form-urlencoded; charset=UTF-8"
  );

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) //private translate: TranslateService,
  {}

  getHome(body: any, removeCart:any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (removeCart)
      {
        this.storage.remove(CONSTANTS.STORAGE.CART).then(() => {
          this.storage.remove(CONSTANTS.STORAGE.USE_TYPE).then(() => {
            this.storage.remove(CONSTANTS.STORAGE.LOCATION_CACHE).then(() => {
              this.storage.remove(CONSTANTS.STORAGE.SEGMENT).then(() => {
                this.storage.get(CONSTANTS.STORAGE.USER).then((user: any) => {
                  if (!!user) {
                    const params = new URLSearchParams();
                    params.append('cep', body.cep);
                    params.append('latitude', body.latitude);
                    params.append('longitude', body.longitude);
                    params.append('bairro', body.bairro);
                    params.append('login_hash', encodeURI(user.login_hash));
                    params.append('v4', '1');
                    // params.append('codSegmento', '0');
                    params.append('codCidade', body.codCidade);
                    const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.NEW_HOME}?${params}`;
                    this.http.get(url, {headers: this.headers}).subscribe((response) => {
                      this.filterHome(response).then((location) => {
                        return resolve(location);
                      }).catch((error) => {
                        console.log('catch error', error);
                        return reject(error);
                      });
                    }, (error) => {
                      //this.errorHome(error);
                      return reject(error);
                    });
                  } else {
                    const params = new URLSearchParams();
                    params.append('cep', body.cep);
                    params.append('latitude', body.latitude);
                    params.append('longitude', body.longitude);
                    params.append('bairro', body.bairro);
                    params.append('login_hash', encodeURI(''));
                    params.append('v4', '1');
                    params.append('codSegmento', '0');
                    params.append('codCidade', body.codCidade);
                    const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.NEW_HOME}?${params}`;
                    console.log('URL NEW LOCATION', url);
                    this.http.get(url, {headers: this.headers}).subscribe((response) => {
                      this.filterHome(response).then((location) => {
                        return resolve(location);
                      }).catch((error) => {
                        console.log('catch error', error);
                        return reject(error);
                      });
                    }, (error) => {
                      //this.errorHome(error);
                      return reject(error);
                    });
                  }
                }).catch(() => {
                  const params = new URLSearchParams();
                  params.append('cep', body.cep);
                  params.append('latitude', body.latitude);
                  params.append('longitude', body.longitude);
                  params.append('bairro', body.bairro);
                  params.append('login_hash', encodeURI(''));
                  params.append('v4', '1');
                  params.append('codSegmento', '0');
                  params.append('codCidade', body.codCidade);
                  const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.NEW_HOME}?${params}`;
                  console.log('URL NEW LOCATION', url);
                  this.http.get(url, {headers: this.headers}).subscribe((response) => {
                    this.filterHome(response).then((location) => {
                      return resolve(location);
                    }).catch((error) => {
                      console.log('catch error', error);
                      return reject(error);
                    });
                  }, (error) => {
                    //this.errorHome(error);
                    return reject(error);
                  });
                });
              });
            });
          });
        });
      } else {
        this.storage.remove(CONSTANTS.STORAGE.USE_TYPE).then(() => {
          this.storage.remove(CONSTANTS.STORAGE.LOCATION_CACHE).then(() => {
            this.storage.remove(CONSTANTS.STORAGE.SEGMENT).then(() => {
              this.storage.get(CONSTANTS.STORAGE.USER).then((user: any) => {
                if (!!user) {
                  const params = new URLSearchParams();
                  params.append('cep', body.cep);
                  params.append('latitude', body.latitude);
                  params.append('longitude', body.longitude);
                  params.append('bairro', body.bairro);
                  params.append('login_hash', encodeURI(user.login_hash));
                  params.append('v4', '1');
                  // params.append('codSegmento', '0');
                  params.append('codCidade', body.codCidade);
                  const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.NEW_HOME}?${params}`;
                  console.log('URL NEW LOCATION', url);
                  this.http.get(url, {headers: this.headers}).subscribe((response) => {
                    this.filterHome(response).then((location) => {
                      return resolve(location);
                    }).catch((error) => {
                      console.log('catch error', error);
                      return reject(error);
                    });
                  }, (error) => {
                    //this.errorHome(error);
                    return reject(error);
                  });
                } else {
                  const params = new URLSearchParams();
                  params.append('cep', body.cep);
                  params.append('latitude', body.latitude);
                  params.append('longitude', body.longitude);
                  params.append('bairro', body.bairro);
                  params.append('login_hash', encodeURI(''));
                  params.append('v4', '1');
                  params.append('codSegmento', '0');
                  params.append('codCidade', body.codCidade);
                  const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.NEW_HOME}?${params}`;
                  console.log('URL NEW LOCATION', url);
                  this.http.get(url, {headers: this.headers}).subscribe((response) => {
                    this.filterHome(response).then((location) => {
                      return resolve(location);
                    }).catch((error) => {
                      console.log('catch error', error);
                      return reject(error);
                    });
                  }, (error) => {
                    //this.errorHome(error);
                    return reject(error);
                  });
                }
              }).catch(() => {
                const params = new URLSearchParams();
                params.append('cep', body.cep);
                params.append('latitude', body.latitude);
                params.append('longitude', body.longitude);
                params.append('bairro', body.bairro);
                params.append('login_hash', encodeURI(''));
                params.append('v4', '1');
                params.append('codSegmento', '0');
                params.append('codCidade', body.codCidade);
                const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.NEW_HOME}?${params}`;
                console.log('URL NEW LOCATION', url);
                this.http.get(url, {headers: this.headers}).subscribe((response) => {
                  this.filterHome(response).then((location) => {
                    return resolve(location);
                  }).catch((error) => {
                    console.log('catch error', error);
                    return reject(error);
                  });
                }, (error) => {
                  //this.errorHome(error);
                  return reject(error);
                });
              });
            });
          });
        });
      }


    });
}

filterHome(location: any): Promise<any> {
    return new Promise((resolve, reject) => {
        location.validate = Date.now();

        location.arrLoja = [];
        location.arrLoja_delivery = [];
        location.arrLoja_retLocal = [];
        location.arrLoja_garcomDigital = [];
        location.arrLoja_reservaMesa = [];
        location.ljs_delivery = [];
        location.ljs_retLocal = [];
        location.ljs_garcomDigital = [];
        location.ljs_reservaMesa = [];
        location.ljs_guias = [];
        location.cat_delivery = [];
        location.cat_retLocal = [];
        location.cat_garcomDigital = [];
        location.cat_reservaMesa = [];
        location.grp_delivery = [];
        location.grp_retLocal = [];
        location.grp_garcomDigital = [];
        location.grp_reservaMesa = [];
        location.qtd_lojas = 0;
        location.qtd_guias = 0;
        location.qtd_delivery = 0;
        location.qtd_retLocal = 0;
        location.qtd_garcomDigital = 0;
        location.qtd_reservaMesa = 0;

        location.ljs_guias = location.lojas.map((store: any) => {
            if (store.delivery > 0) {
                location.arrLoja_delivery.push(store.codLoja);
                location.ljs_delivery.push(store);
            }
            if (store.retLocal > 0) {
                location.arrLoja_retLocal.push(store.codLoja);
                location.ljs_retLocal.push(store);
            }
            if (store.garcomDigital > 0) {
                location.arrLoja_garcomDigital.push(store.codLoja);
                location.ljs_garcomDigital.push(store);
            }
            if (store.reservaMesa > 0) {
                location.arrLoja_reservaMesa.push(store.codLoja);
                location.ljs_reservaMesa.push(store);
            }
            location.arrLoja.push(store.codLoja);
            return store;
        });

        location.categorias.map((category: any) => {
            if ((category.jsonLoja
                .split(', ')
                .filter((item: any) => (location.arrLoja_delivery.indexOf(item) !== -1)).length > 0)) {
                location.cat_delivery.push(category);
            }
            if ((category.jsonLoja
                .split(', ')
                .filter((item: any) => (location.arrLoja_retLocal.indexOf(item) !== -1)).length > 0)) {
                location.cat_retLocal.push(category);
            }
            if ((category.jsonLoja
                .split(', ')
                .filter((item: any) => (location.arrLoja_garcomDigital.indexOf(item) !== -1)).length > 0)) {
                location.cat_garcomDigital.push(category);
            }
            if ((category.jsonLoja
                .split(', ')
                .filter((item: any) => (location.arrLoja_reservaMesa.indexOf(item) !== -1)).length > 0)) {
                location.cat_reservaMesa.push(category);
            }
        });

        location.grupos.map((group: any) => {
            if ((group.LojasJSON
                .split(', ')
                .filter((item: any) => (location.arrLoja_delivery.indexOf(item) !== -1)).length > 0)) {
                location.grp_delivery.push(group);
            }
            if ((group.LojasJSON
                .split(', ')
                .filter((item: any) => (location.arrLoja_retLocal.indexOf(item) !== -1)).length > 0)) {
                location.grp_retLocal.push(group);
            }
            if ((group.LojasJSON
                .split(', ')
                .filter((item: any) => (location.arrLoja_garcomDigital.indexOf(item) !== -1)).length > 0)) {
                location.grp_garcomDigital.push(group);
            }
            if ((group.LojasJSON
                .split(', ')
                .filter((item: any) => (location.arrLoja_reservaMesa.indexOf(item) !== -1)).length > 0)) {
                location.grp_reservaMesa.push(group);
            }
        });

        location.qtd_guias = location.arrLoja.length;
        location.qtd_delivery = location.arrLoja_delivery.length;
        location.qtd_retLocal = location.arrLoja_retLocal.length;
        location.qtd_garcomDigital = location.arrLoja_garcomDigital.length;
        location.qtd_reservaMesa = location.arrLoja_reservaMesa.length;
        location.qtd_lojas = location.arrLoja.length;

        this.storage.set(CONSTANTS.STORAGE.LOCATION_CACHE, location).then((location: any) => {
            const types = [];

            if (parseFloat(location.qtd_delivery) > 0) {
                types.push('D');
            }

            if (parseFloat(location.qtd_retLocal) > 0) {
                types.push('RT');
            }

            if (parseFloat(location.qtd_guias) > 0) {
                types.push('G');
            }

            this.storage.set(CONSTANTS.STORAGE.USE_TYPE, types[0]).then(() => {
                this.storage.set(CONSTANTS.STORAGE.SEGMENT, location.segmentos[0]).then(() => {
                    return resolve(location);
                }).catch((error: any) => {
                    console.log('catch error', error);
                    return reject(error);
                });
            }).catch((error: any) => {
                console.log('catch error', error);
                return reject(error);
            });
        }).catch((error: any) => {
            console.log('catch error', error);
            return reject(error);
        });
    });
}

async notCacheStores(address: any): Promise<any> {
    return new Promise((resolve, reject) => {
        this.storage.remove(CONSTANTS.STORAGE.USE_TYPE).then(() => {
            this.storage.remove(CONSTANTS.STORAGE.LOCATION_CACHE).then(() => {
                const body = {
                    cep: '',
                    latitude: address.latitude,
                    longitude: address.longitude,
                    bairro: address.bairro,
                    codCidade: address.codCidade
                };
                this.getHome(body, '1').then((home) => {
                    return resolve(home);
                }).catch((error) => {
                    return reject(error);
                });
            }).catch((error: any) => {
                return reject(error);
            });
        }).catch((error: any) => {
            return reject(error);
        });
    });
}
}
