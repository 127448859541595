import { Component, Input, OnInit } from "@angular/core";
import * as moment from "moment";
import { CONSTANTS } from "src/app/constants";

@Component({
  selector: "app-order-tracking",
  templateUrl: "./order-tracking.component.html",
  styleUrls: ["./order-tracking.component.scss"],
})
export class OrderTrackingComponent implements OnInit {
  @Input() pedido: any;
  @Input() closeModal: () => void;
  imageUrl = CONSTANTS.CONFIG.IMG_URL;
  formaPagamento: string;

  timeLine = {
    nivel1: true,
    nivel2: true,
    nivel3: true,
    nivel4: true,
    nivel5: true,
  };

  constructor() {
    this.formaPagamento = 'dinheiro'
  }

  ngOnInit() {
    moment.locale("pt-br");
    let prevMin = new Date(parseInt(this.pedido.dataPrevistaMin));
    let prevMax = new Date(parseInt(this.pedido.dataPrevistaMax));
    let phoneNumber = this.pedido.jsonLoja.telefone;
    this.pedido.dataPrevistaMin = moment(prevMin).format("HH:mm");
    this.pedido.dataPrevistaMax = moment(prevMax).format("HH:mm");
    console.log(this.pedido);
  }
}
