import { CardService } from "src/app/services/card.service";
import { StorageService } from "./../../services/storage.service";
import { AlertService } from "./../../services/alert.service";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { IonInput } from "@ionic/angular";
import * as creditCardType from "credit-card-type";
import { SimpleMaskDirective } from "ngx-ion-simple-mask";
import { CONSTANTS } from "src/app/constants";

@Component({
  selector: "app-credit-card",
  templateUrl: "./credit-card.component.html",
  styleUrls: ["./credit-card.component.scss"],
})
export class CreditCardComponent implements OnInit {
  @Input() cancelar: () => void;
  @Input() adicionar: () => void;
  @Input() cartao: any;
  shortcode: string;
  constructor(
    private toastr: AlertService,
    private storage: StorageService,
    private cardService: CardService
  ) {}

  verificaCampos(cod) {
    console.log(cod);

    if (this.shortcode === null || this.shortcode.length < 6) {
      this.toastr.genericToast(
        "Atenção",
        "Há campos incompletos ou inválidos",
        "warning",
        "bottom"
      );
    }
    this.montaObj(this.cartao);
    this.saveCardInternal(this.cartao);
  }

  montaObj(card) {
    console.log(card);

    this.cartao = {
      holder: this.cartao.holder,
      number: this.cartao.number,
      expiration: this.cartao.expiration,
      token: "",
      brand: this.cartao.brand,
      cvv: this.cartao.cvv,
      cpf: this.cartao.cpf,
      shortCode: this.shortcode,
    };
  }

  saveCardInternal(card) {
    // this.atualizaValorCC();s
    this.montaObj(card);
    console.log("Card: ", this.cartao);
    this.storage
      .get(CONSTANTS.STORAGE.USER)
      .then((user) => {
        this.cardService
          .saveCard(user.login_hash, this.cartao)
          .then((saveCardResponse) => {
            console.log("saveCardResponse", saveCardResponse);
            if (saveCardResponse.status) {
              this.toastr.genericToast(
                "Cartão adicionado!",
                "Cartão adicionado com sucesso.",
                "success",
                "bottom"
              );
              this.cartao.CardToken = saveCardResponse.payment_method.card.id;
              this.cancelar();
            }
          })
          .catch((error) => {
            console.log("SaveCardError: ", error);
            if (error.error.message === "Limite de cartões") {
              this.toastr.genericToast(
                "Limite de cartões atingido, para cadastrar delete um dos cartões",
                "Limite atingido.",
                "error",
                "bottom"
              );
            } else {
              this.toastr.genericToast(
                "Dados do cartão de crédito inválidos.",
                "Atenção",
                "error",
                "bottom"
              );
            }
            this.cartao = {
              holder: "",
              number: "",
              expiration: "",
              CardToken: "",
              brand: "",
              cvv: "",
              cpf: "",
            };
          });
      })
      .catch((error) => {
        console.log("StorageError:", error);
      });
  }

  isCardValid() {
    return (
      this.cartao.holder !== "" &&
      this.cartao.number !== "" &&
      this.cartao.expiration !== "" &&
      this.cartao.cvv !== "" &&
      this.cartao.brand !== "" &&
      // this.cartao.CardToken != '' &&
      this.cartao.cpf !== ""
    );
  }
  isNameValid(name: string) {
    return !!name
      ? !!name.match(/^[A-zÀ-ÿ][A-zÀ-ÿ']+\s([A-zÀ-ÿ']\s?)*[A-zÀ-ÿ][A-zÀ-ÿ']+$/)
      : false;
  }

  ngOnInit() {
    console.log(this.cartao);
  }
}
