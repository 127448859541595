import { AlertService } from "./../../services/alert.service";
import { StorageService } from "./../../services/storage.service";
import { CupomService } from "./../../services/cupom.service";
import { ModalController } from "@ionic/angular";
import { Component, Input, OnInit } from "@angular/core";
import { CONSTANTS } from "src/app/constants";

@Component({
  selector: "app-cupom",
  templateUrl: "./cupom.component.html",
  styleUrls: ["./cupom.component.scss"],
})
export class CupomComponent implements OnInit {
  @Input() cupons: any = [];
  cuponsPesquisa: any;
  loginHash: any;
  codCupom: any;
  newCart: any = [];
  cor: any;
  logo: any;
  constructor(
    private modalCtrl: ModalController,
    private toastr: AlertService,
    private cupom: CupomService,
    private storage: StorageService
  ) {
    storage.get("Afiliado").then((res: any) => {
      this.logo = CONSTANTS.CONFIG.IMG_URL + res.logo;
      console.log(this.logo);

      this.cor = res.cor;
    });
  }

  ngOnInit() {
    console.log(this.cupons);
  }

  async selectCupom(cupom) {
    console.log(cupom.valor);

    await this.storage
      .get("cart")
      .then((res) => {
        console.log(res);
        res.valorTotal = res.valorTotal - cupom.valor;

        this.storage.set("cart", res).then((res) => {
          console.log("cupom aplicado com sucesso");
          console.log(res);
          this.newCart = res;
        });
      })
      .catch((e) => {
        console.log(e);
      });

    this.modalCtrl.dismiss({
      cupom: cupom,
      newCart: this.newCart,
    });
  }

  async getToken() {
    this.storage
      .get("USER")
      .then((res) => {
        this.loginHash = res.login_hash;
      })
      .catch((e) => {
        console.log(e);
      });
  }

  async buscarCupom(codCupom) {
    console.log(codCupom);

    this.storage.get("cart").then(async (res) => {
      await this.getToken();
      this.cupom
        .buscarCupom(this.loginHash, res.jsonLoja.codLoja, codCupom)
        .then((res) => {
          console.log(res);
          this.cuponsPesquisa = res;
          this.cupons = [];
          console.log(this.cupons);
          this.toastr.genericToast(
            "Sucesso",
            "Código recuperado com sucesso",
            "success",
            "bottom"
          );
        })
        .catch((err) => {
          this.toastr.genericToast(
            "Código não encontrado",
            "Código inválido ou inexistente",
            "error",
            "bottom"
          );
        });
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
