import { OrderTrackingComponent } from "./../order-tracking/order-tracking.component";
import { ModalController } from "@ionic/angular";
import { Component, Input, OnInit } from "@angular/core";
import { CONSTANTS } from "src/app/constants";

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
})
export class DetailsComponent implements OnInit {
  @Input() pedido: any;
  @Input() closeModal: () => void;
  imageUrl = CONSTANTS.CONFIG.IMG_URL;

  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {
    console.log(this.pedido);
    this.openOrderTracking();
  }

  async openOrderTracking() {
    const modal = await this.modalCtrl.create({
      component: OrderTrackingComponent,
      cssClass: "modalOrderTracking",
      componentProps: {
        pedido: this.pedido,
        closeModal: () => {
          modal.dismiss();
        },
      },
    });

    await modal.present();

    const { data, role } = await modal.onWillDismiss();
  }
}
