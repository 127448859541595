import { environment } from "./environments/environment.prod";


export const CONSTANTS = {
  MASKS: {
    55: {
      mask: '(00) 00000-0000',
      len: 15
    },
    351: {
      mask: '00-000-0000',
      len: 11
    },
    34: {
      mask: '000 000 000',
      len: 11
    },
  },
  CONFIG: {
    // API URL
    IMG_PLACEHOLDER: '/assets/images/product/placeholder_img.png',
    IMG_URL: 'https://portal.ceofood.com.br/',
    SOCKET: 'ws://socket.ceofood.com.br:7412',
    BASE_URL: 'https://portal.ceofood.com.br/rest2.php/',
    BASE_URL_OLD: 'https://portal.ceofood.com.br/rest.php/',
    BASE_API_URL: 'https://portal.ceofood.com.br/api.php/',
    BASE_URL_REST_ENTREGADOR: 'https://portal.ceofood.com.br/restEntregador.php/',
    TEST_URL: 'https://portal.ceofood.com.br/rest.test.php/',
    AFILIADO_URL: "restAfiliado.php/afiliado?url=",

    // BASE_URL: 'http://10.0.0.107:8000/rest2.php/',
    // BASE_URL_OLD: 'http://10.0.0.107:8000/rest.php/',
    // BASE_API_URL: 'http://10.0.0.107:8000/api.php/',


    ORDER_URL: environment.URL,

    // LOCALHOST URL
    LOCAL_URL: 'http://localhost:8000/rest2.php/',

    GET_ENTREGADOR_PEDIDO: 'getEntregadorByCodPedido',

    // BASE_URL: 'http://preproducao.ceofood.com.br/rest2.php/',
    // BASE_URL_OLD: 'http://preproducao.ceofood.com.br/rest.php/',
    // BASE_API_URL: 'http://preproducao.ceofood.com.br/api.php/',

    // GEO
    FREEGEOIP: 'https://freegeoip.app/json/',
    GEOPLUGIN: 'geoplugin?ip=',
    GET_PAIS_CODE: 'getCountryByCode?countryCode=',

    // PAG_SEGURO
    PAG_SEGURO: 'pagseguro',
    SESSION: '/session',
    GET_SENDER_HASH: '/getSenderHash',
    GET_BRAND: '/getBrand',
    CREATE_CARD_TOKEN: '/createCardToken',
    IMG_URL_PAGSEGURO: 'https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/',

    // IDS
    COUNTRY_ID: null,
    STATE_ID: null,
    CITY_ID: null,

    // GET REGION
    COUNTRY: 'pais',
    STATE: 'uf',
    CITY: 'cidade',

    // GET LOJAS
    LOJAS: 'loja',
    LOJAS_PROXIMAS: 'lojasproximas',
    LOJAS_FAVORITAS: 'favoritoLojas',
    ADD_FAVORITOS: 'favoritar',
    REMOVE_FAVORITOS: 'removerFavorito',
    COMENTARIO: 'comentario',
    GRUPO: 'grupo',
    CATEGORIAS: 'categoriaLoja',
    GRUPOS: 'grupoLoja',
    OFERTAS: 'ofertas',
    STOREHOUR: 'lojasAbertas',
    REVIEW_LIST: 'lojaComentarios',
    CAT_PRODUTOS: 'categoriaProduto',
    CIDADE_LOJA: 'getCidade',

    // USER
    LOGIN: 'login',
    REGISTER: 'register',
    REMOVE_USUARIO: 'removeUsuario',
    PRIVACY: 'config',
    TERMS_PRIVACY: 'termsPrivacy',
    CHECK_EMAIL: 'checkemail',
    CHECK_PHONE: 'checkPhone',
    // CHECK_WHATSAPP: 'checkWhatsApp',
    // CHECK_WHATSAPP_STATUS: 'checkWhatsAppStatus',
    REVOVER_PASS: 'recuperarSenha',
    EDIT_USER: 'editarUsuario',
    REMOVE_PUSH: 'removerOneSignal',
    CHECK_HASH: 'dadosUsuario',
    ENDERECO: 'endereco',
    ENDERECO_GPS: 'enderecoGPS',
    BUSCAR_ENDERECO: 'buscarEndereco',
    REGISTER_ENDERECO: 'adicionarEndereco',
    ADD_ENDERECO: 'adicionarEndereco',
    CEP_SEARCH: 'enderecoCEP',
    NEW_HOME: 'NewHome',
    ATIVAR_ENDERECO: 'ativarEndereco',
    ADDRESSENABLED: 'enderecoAtivo',
    ADDRESSREMOVE: 'desativarEndereco',
    SEARCH_ADDRESS: 'searchAddress',
    CIDADE_BY_DESCRICAO: 'getCidadeByDescricao',
    CLEAR_ONE_SIGNAL: 'clearIdOneSignal',
    GET_USER_BY_PHONE: 'getUserByPhone',

    // PEDIDO
    NEW_ORDER: 'pedidoNew',
    SEND_CONTACT: 'contato',
    SEND_CONTACT_RECOMEND: 'contatoRecomend',
    ORDERS: 'meuspedidos',
    ORDERS_FULL: 'pedidoCompleto',
    ORDER_LIST: 'v4/meuspedidos',
    ORDER: 'v4/pedido',
    CALCTAXA: 'taxaEntrega',
    HOME: 'NewHome',
    SEARCH: 'buscaNew',
    ADD_CARD: 'pagamentoCartao',
    CREATE_CARD: 'criarCartao',
    TWO_FACTOR: 'twoFactor',
    GET_CARTAO: 'getCartao',
    REMOVE_CARTAO: 'deletarCartao',
    GET_CUPONS: 'cupons',
    BUSCAR_CUPOM: 'buscarCupom',
    CHECK_CUPONS: 'validaCupom',
    OPTIONS: 'produto',
    PRODUTO: 'produto',
    PRODUTO_NEW: 'produtoNew',
    PRODUTO_VARICAO: 'newProdutoVariacao',
    ORDER_OPENS: 'v4/pedidosabertos',
    ORDER_LAST: 'v4/ultimospedidos',
    GET_BANNERS: 'bannerApp',
    ALTERAR_LOCAL: 'alterarEnderecoPedido',
    DOC_PEDIDO: 'pedidoDocumento',
    GET_PAYMENT_QR_CODE: 'getPaymentQrCode',


    SEGMENTO: 'segmento',
    GET_COUNTRY: 'getCountry',

    // RESERVA MESA
    RESERVAS_DISPONIVEIS: 'reservasDisponiveis',
    RESERVAR_MESA: 'reservarMesa',
    MINHAS_RESERVAS: 'minhasReservas',

    // CONTA DIGITAL
    CASH: {
      CHECK_CPF: 'checkCPF',
      BASE: 'https://portal.ceofood.com.br/restCarteira.php/',
      CREATE_ACCOUNT: 'criarContaDigital',
      SALDO: 'consultaSaldoContaFilha',
      CONSULTAR_CARTEIRA_CREDITO: 'consultarCarteiraCredito',
      GERAR_BOLETO: 'gerarBoleto',
      INFO_BOLETO: 'infoBoleto',
      PAGAR_CONTA: 'pagarConta',
      VIRTUAL_CARD: 'consultarCartaoVirtual',
      CANCELAR_CARTAO: 'cancelarCartao',
      BLOQUEAR_CARTAO: 'bloquearCartao',
      DESBLOQUEAR_CARTAO: 'desbloquearCartao',
      TRANSFERIR_CONTA: 'transferenciaConta',
      TED: 'ted',
      CHECK_FINANCIAL_OPERATION_KEY: 'checkFinancialOperationKey',
      EXTRATO_CONTA: 'extratoConta',
      EXTRATO_CARTAO: 'extratoCartao',
      EDITAR_CONTA_DIGITAL: 'editarContaDigital',
      REEMITIR_CARTAO: 'reemitirCartao',
      MUDAR_SENHA: 'mudarSenha',
      LISTAR_BOLETO: 'listarBoleto',
      // CONSULTA_EXTRATO: 'card/consulta-extrato',
      // ALTERAR_MEUS_DADOS: 'card/atualizar_dados',
    },
  },

  STORAGE: {
    choosenCountry: 'ceofoodCountry',
    choosenState: 'ceofoodState',
    choosenCity: 'ceofoodCity',
    choosenCityName: 'ceofoodCityName',
    userPosition: 'userPosition',
    storeFavorite: 'storeFavorite',
    OPEN_ORDERS: 'open_orders',
    APPRATE: 'rate',
    COUNTRY: 'country',
    USE_TYPE: 'useType',
    USER: 'USER',
    LOCATION_CACHE: 'cacheStores',
    SEGMENT: 'segment',
    ADDRESS: 'address',
    CART: 'cart',
    TEMP_ADDRESS: 'tempAddressCheck',
    FIRST_OPEN: 'firstOpne',
  },

  TOASTR_TIME: {
    FAST: 3000,
    NORMAL: 5000,
    SLOW: 7000
  },

  RECAPTCHA: {
    SITE_KEY: '6Lef-N0UAAAAAIbZqDk30a9l9jtQ1DaTTZFMq0Vp',
  },

  versionCode: '1.0'
};
