import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { resolve } from "dns";
import { promise } from "protractor";
import { CONSTANTS } from "../constants";

@Injectable({
  providedIn: "root",
})
export class RestaurantServiceService {
  constructor(private http: HttpClient) {}

  getStore(codLoja: any, type: any): Promise<any> {
    return new Promise((resolve, reject) => {
      const url = `${CONSTANTS.CONFIG.BASE_URL}${CONSTANTS.CONFIG.LOJAS}?codLoja=${codLoja}&tipo=${type}`;
      this.http.get(url).subscribe((resp) => {
        return resolve(resp);
        console.log(url);
        
      }),
        (error) => {
          return reject(error);
        };
    });
  }

  getStoreCity(codCidade:any): Promise<any> {
    let cidade = codCidade;
    return new Promise((resolve, reject) => {
      const url = `${CONSTANTS.CONFIG.BASE_URL + CONSTANTS.CONFIG.CIDADE_LOJA}?codCidade=${encodeURIComponent(cidade)}`;
      this.http.get(url).subscribe(resp => {
        return resolve(resp);
      }, (error) => {
        return reject(error);
      });
    });
  }
}
